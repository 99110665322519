



























































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PlayHelp extends Vue {
  @Prop(Boolean) dialog = false;

  get show(): boolean {
    return this.dialog;
  }

  set show(value: boolean) {
    this.$emit("close");
  }
}
