import { UAParser } from "ua-parser-js";

const parser = new UAParser();

export enum MobileOS {
  ANDROID,
  IOS,
  OTHER,
}

export enum BrowserType {
  CHROME,
  CHROME_WEBVIEW,
  EDGE,
  FACEBOOK,
  FIREFOX,
  OPERA,
  SAFARI,
  SAMSUNG,
  OTHER,
}

export enum OrientationMode {
  PORTRAIT,
  LANDSCAPE,
}

export function isMobile(): boolean {
  return (
    parser.getDevice().type === "mobile" ||
    parser.getDevice().type === "tablet" ||
    parser.getDevice().type === "embedded"
  );
}

export function getMobileOS(): MobileOS {
  if (parser.getOS().name === "Android") return MobileOS.ANDROID;
  if (parser.getOS().name === "iOS") return MobileOS.IOS;
  return MobileOS.OTHER;
}

export function getBrowserType(): BrowserType {
  console.log("BROWSER", parser.getBrowser().name);
  if (parser.getBrowser().name === "Chrome") return BrowserType.CHROME;
  if (parser.getBrowser().name === "Chrome WebView") return BrowserType.CHROME_WEBVIEW;
  if (parser.getBrowser().name === "Mobile Safari") return BrowserType.SAFARI;
  if (parser.getBrowser().name === "Samsung Browser") return BrowserType.SAMSUNG;
  if (parser.getBrowser().name === "Opera") return BrowserType.OPERA;
  if (parser.getBrowser().name === "Firefox") return BrowserType.FIREFOX;
  if (parser.getBrowser().name === "Edge") return BrowserType.EDGE;
  if (parser.getBrowser().name === "Facebook") return BrowserType.FACEBOOK;
  return BrowserType.OTHER;
}

export function getMobileOrientation(): OrientationMode {
  return window.innerHeight > window.innerWidth ? OrientationMode.PORTRAIT : OrientationMode.LANDSCAPE;
}

export function isFacebookApp(): boolean {
  return parser.getUA().indexOf("FBAN") > -1 || parser.getUA().indexOf("FBAV") > -1;
}
