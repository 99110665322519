/* eslint-disable @typescript-eslint/no-explicit-any */
import config from "./Config";
import * as WebSocketConnection from "./WebSocketConnection";
import * as MobileManager from "./MobileManager";
import * as Stats from "./Stat";
import * as URLParamsManager from "./URLParamsManager";
import * as Utils from "./Utils";
import { ParsedWebRTCStats, WebRTCStatsParser } from "../WebRTC/WebRTCStatsParser";

export default class VideoController {
  public videoStatsParser: WebRTCStatsParser | undefined;
  public audioStatsParser: WebRTCStatsParser | undefined;
  public webRTCId: string | undefined = undefined;

  private onConnect: () => void;
  private onStat: () => void;
  private onFrame: () => void;

  private rtcVideoPeerConnection: RTCPeerConnection | null;
  private rtcAudioPeerConnection: RTCPeerConnection | null;
  private peerDataChannel: RTCDataChannel | null;
  private videoElement: HTMLVideoElement;
  private mediaStream: MediaStream;
  private mouseDown: boolean;
  private lastTime: number;

  private touchStartRef = this.TouchStart.bind(this);
  private touchMoveRef = this.TouchMove.bind(this);
  private touchEndRef = this.TouchEnd.bind(this);

  private statId: string;
  private hostId: string;
  private renegociationTimeout: number | undefined;
  private renegociationVideoFormat: boolean;
  private displayed: boolean;

  private VueManager: any;

  private lastBytesReceived: number;

  constructor(VueManager: any, statId: string) {
    this.VueManager = VueManager;
    this.statId = statId;
    this.renegociationTimeout = undefined;
    this.renegociationVideoFormat = false;
    this.lastBytesReceived = 0;
    this.displayed = false;
    this.onConnect = () => {
      return;
    };
  }

  set OnConnect(cb: () => void) {
    this.onConnect = cb;
  }

  set OnStat(cb: () => void) {
    this.onStat = cb;
  }

  set OnFrame(cb: () => void) {
    this.onFrame = cb;
  }

  public InitTouch(videoElement: HTMLVideoElement): void {
    console.debug("(WebRTC) Initializing touch listeners on the video");
    const node = document.querySelector("#presstoplay");
    if (node) {
      node.classList.remove("d-none");
      node.addEventListener("click", () => {
        videoElement.pause();
        videoElement.play();
        node.classList.add("d-none");
      });
      const isiOS = MobileManager.getMobileOS() === MobileManager.MobileOS.IOS;
      if (!(URLParamsManager.FORCE_PRESS || isiOS)) videoElement.onplaying = () => node.classList.add("d-none");
    }

    this.mouseDown = false;
    this.lastTime = 0;
    videoElement.addEventListener("touchstart", this.touchStartRef);
    videoElement.addEventListener("mousedown", this.touchStartRef);

    videoElement.addEventListener("touchmove", this.touchMoveRef);
    videoElement.addEventListener("mousemove", this.touchMoveRef);

    videoElement.addEventListener("touchend", this.touchEndRef);
    videoElement.addEventListener("mouseup", this.touchEndRef);
  }

  public Close(): void {
    this.videoStatsParser?.stop();
    this.videoStatsParser = undefined;

    this.audioStatsParser?.stop();
    this.audioStatsParser = undefined;

    this.videoElement?.removeEventListener("touchstart", this.touchStartRef);
    this.videoElement?.removeEventListener("mousedown", this.touchStartRef);

    this.videoElement?.removeEventListener("touchmove", this.touchMoveRef);
    this.videoElement?.removeEventListener("mousemove", this.touchMoveRef);

    this.videoElement?.removeEventListener("touchend", this.touchEndRef);
    this.videoElement?.removeEventListener("mouseup", this.touchEndRef);

    if (this.videoElement) this.videoElement.srcObject = null;

    this.closeRTCVideoPeerConnection();
    this.closeRTCAudioPeerConnection();
  }

  public SendLaunchApp(app: string): void {
    this.peerDataChannel?.send(JSON.stringify({ type: "APP", data: app }));
  }

  public IsReady(): boolean {
    if (!this.rtcVideoPeerConnection || !this.peerDataChannel) return false;
    return this.rtcVideoPeerConnection.connectionState === "connected" && this.peerDataChannel.readyState === "open";
  }

  /**
   * Start the WebRTC peering.
   */
  public StartVideoWebRTC(videoElement: HTMLVideoElement): void {
    this.videoElement = videoElement;
    this.mediaStream = new MediaStream();
    this.initVideoRTCPeerConnection();
    this.initAudioRTCPeerConnection();
  }

  public SetHostId(hostId: string): void {
    this.hostId = hostId;
  }

  public DataStop(): void {
    console.log("STOPPPING THE Data flow");
    this.peerDataChannel?.close();
    if (this.videoStatsParser) this.videoStatsParser.stop();
  }

  private get iceServers(): RTCIceServer[] {
    let res: RTCIceServer[] = this.VueManager.config.iceServers;
    if (!res || res.length === 0) res = config.peerConnectionConfig.iceServers;
    return res;
  }

  private initVideoRTCPeerConnection(): void {
    this.rtcVideoPeerConnection = new RTCPeerConnection({
      iceServers: this.iceServers,
    });

    if (URLParamsManager.DEBUG) (window as any).PC = (this.rtcVideoPeerConnection as any)._pc;

    this.setOnDataChannel(this.rtcVideoPeerConnection, WebSocketConnection.RTCMode.Video);

    this.setSignalingCallbacks(this.rtcVideoPeerConnection, WebSocketConnection.RTCMode.Video);

    this.rtcVideoPeerConnection.ontrack = (event: any) => {
      console.debug("(Video) Received a video track");
      const videoTracks = this.mediaStream.getVideoTracks();
      if (videoTracks.length > 0) {
        // Removing old track in case if me receive a new Video Track
        console.info("Removing old Video Track");
        this.mediaStream.removeTrack(videoTracks[0]);
        this.videoStatsParser?.stop();
      }

      this.mediaStream.addTrack(event.streams[0].getVideoTracks()[0]);
      this.videoElement.srcObject = this.mediaStream;
      console.log("(Video) Inserted stream into the player");

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.rtcVideoPeerConnection)
        this.videoStatsParser = new WebRTCStatsParser(
          this.rtcVideoPeerConnection,
          1000,
          this.VueManager.browserType,
          this.VueManager.speedtestResultPing
        );
      this.videoStatsParser?.on("stats", (stats) => {
        this.processStat(stats);
      });

      if (this.IsReady()) this.onConnect();
    };
  }

  private initAudioRTCPeerConnection(): void {
    this.rtcAudioPeerConnection = new RTCPeerConnection({
      iceServers: this.iceServers,
    });

    this.setSignalingCallbacks(this.rtcAudioPeerConnection, WebSocketConnection.RTCMode.Audio);

    this.rtcAudioPeerConnection.ontrack = (event: any) => {
      console.debug("(Audio) Received an audio track");

      this.mediaStream.addTrack(event.streams[0].getAudioTracks()[0]);
      console.log("(Audio) Inserted stream into the player");

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.rtcAudioPeerConnection)
        this.audioStatsParser = new WebRTCStatsParser(
          this.rtcAudioPeerConnection,
          1000,
          this.VueManager.browserType,
          this.VueManager.speedtestResultPing
        );
      this.audioStatsParser?.on("stats", (stats) => {
        const StatWebRTC: Stats.StatSession = {
          date: Stats.GetDate(),
          entrypoint_ip: window.location.host,
          host_id: this.hostId,
          log_type: "client_session_stat",
          stat_id: this.statId,
          url: window.location.href,
          network_score: this.VueManager.networkStrengh,
          username: this.VueManager.$store.state.authentication.user.username,
          webrtc_session_id: this.webRTCId,
          webrtc_stats: stats,
        };
        Stats.SendStats(StatWebRTC);
        this.VueManager.webrtcAudioStats = stats;
      });

      if (this.IsReady()) this.onConnect();
    };
  }

  private setOnDataChannel(RTCPeerConnection: RTCPeerConnection, mode: WebSocketConnection.RTCMode): void {
    RTCPeerConnection.ondatachannel = (ev: RTCDataChannelEvent) => {
      console.debug(`(${mode} Data) Received Data Channel creation request from peer`);
      ev.channel.onopen = () => {
        console.log(`(${mode} Data) Data Channel opened`);
        // Close the previous Speedtest Data channel
        if (mode === WebSocketConnection.RTCMode.Video && this.peerDataChannel) this.peerDataChannel.close();
        this.peerDataChannel = ev.channel;
        clearTimeout(this.renegociationTimeout);
        if (this.IsReady()) this.onConnect();
      };
      ev.channel.onmessage = (msgEvent) => {
        console.debug("(Data) on-data: " + msgEvent.data.toString());
      };
    };
  }

  private setSignalingCallbacks(RTCPeerConnection: RTCPeerConnection, mode: WebSocketConnection.RTCMode): void {
    RTCPeerConnection.onicecandidate = (data) => {
      if (!RTCPeerConnection) return;
      if (data.candidate === null) {
        console.log(`(WebRTC ${mode}) Finished gathering Ice candidate`);
      } else {
        WebSocketConnection.SendRTCMessage({ ice: data.candidate }, mode);
      }
    };

    RTCPeerConnection.onconnectionstatechange = () => {
      if (!RTCPeerConnection) return;
      const message = `(WebRTC ${mode}) Connection state changed to ` + RTCPeerConnection.connectionState.toString();
      if (RTCPeerConnection.connectionState === "failed") console.error(message);
      else if (RTCPeerConnection.connectionState === "disconnected") console.warn(message);
      else console.debug(message);
    };

    RTCPeerConnection.oniceconnectionstatechange = () => {
      if (!RTCPeerConnection) return;
      console.debug(
        `(WebRTC ${mode}) Ice connection state change to ` + RTCPeerConnection.iceConnectionState.toString()
      );
    };

    RTCPeerConnection.onicegatheringstatechange = () => {
      if (!RTCPeerConnection) return;
      console.debug(
        `(WebRTC ${mode}) Ice gathering state changed to ` + RTCPeerConnection.iceGatheringState.toString()
      );
    };

    RTCPeerConnection.onsignalingstatechange = () => {
      if (!RTCPeerConnection) return;
      console.debug(`(WebRTC ${mode}) Signaling state changed to ` + RTCPeerConnection.signalingState.toString());
    };
  }

  private processStat(stats: ParsedWebRTCStats): void {
    const StatWebRTC: Stats.StatSession = {
      date: Stats.GetDate(),
      entrypoint_ip: window.location.host,
      host_id: this.hostId,
      log_type: "client_session_stat",
      stat_id: this.statId,
      url: window.location.href,
      network_score: this.VueManager.networkStrengh,
      username: this.VueManager.$store.state.authentication.user.username,
      webrtc_session_id: this.webRTCId,
      webrtc_stats: stats,
    };
    Stats.SendStats(StatWebRTC);
    if (this.peerDataChannel && stats.inboundRTPVideoStream) {
      if (!this.displayed && stats.inboundRTPVideoStream.framesDecoded > 0) {
        this.displayed = true;
        this.onFrame();
      }
      this.peerDataChannel?.send(
        JSON.stringify({
          type: "STAT",
          data: {
            ping: stats.inboundRTPVideoStream.transport.selectedCandidatePair.currentRoundTripTime * 1000,
            bandwidth: stats.inboundRTPVideoStream.transport.selectedCandidatePair.availableIncomingBitrate / 1000,
            videoJitter:
              (stats.inboundRTPVideoStream.track.jitterBufferDelay /
                stats.inboundRTPVideoStream.track.jitterBufferEmittedCount) *
              1000,
            packetLost: stats.inboundRTPVideoStream.packetsLost,
            packetReceived: stats.inboundRTPVideoStream.packetsReceived,
          },
        })
      );
    }
    this.VueManager.webrtcVideoStats = stats;
    if (
      stats.inboundRTPVideoStream?.bytesReceived &&
      this.lastBytesReceived !== stats.inboundRTPVideoStream?.bytesReceived
    ) {
      this.lastBytesReceived = stats.inboundRTPVideoStream?.bytesReceived;
      if (this.onStat) this.onStat();
    }
  }

  private closeRTCVideoPeerConnection(): void {
    if (this.rtcVideoPeerConnection) this.rtcVideoPeerConnection?.close();
    this.rtcVideoPeerConnection = null;
    if (this.peerDataChannel) this.peerDataChannel?.close();
    this.peerDataChannel = null;
  }

  private closeRTCAudioPeerConnection(): void {
    if (this.rtcAudioPeerConnection) this.rtcAudioPeerConnection?.close();
    this.rtcAudioPeerConnection = null;
  }

  public async HandleRTCMessage(
    message: WebSocketConnection.ICEorSDP,
    mode: WebSocketConnection.RTCMode
  ): Promise<void> {
    if (this.isRTCSDP(message)) return await this.HandleRTCSDP(message, mode);
    if (message.candidate) return await this.HandleRTCIce(message.candidate, mode);
    return Promise.reject(new Error("(WebRTC) Unknown RTC Message:" + message));
  }

  public setWebRTCId(webRTCId: string | undefined): void {
    this.webRTCId = webRTCId;
  }

  private isRTCSDP(arg: any): arg is RTCSessionDescriptionInit {
    return arg.type !== undefined;
  }

  private async HandleRTCSDP(sdp: RTCSessionDescriptionInit, mode: WebSocketConnection.RTCMode): Promise<void> {
    console.warn("Received SDP");
    if (
      (!this.rtcVideoPeerConnection && mode === WebSocketConnection.RTCMode.Video) ||
      (!this.rtcAudioPeerConnection && mode === WebSocketConnection.RTCMode.Audio)
    )
      return;
    this.renegociationVideoFormat = false;
    clearTimeout(this.renegociationTimeout);
    // If the browser does not support the video format, send a message to change the format.
    const videoFormatRenegociationTimeOut = this.VueManager.config?.videoFormatRenegociationTimeOut;
    setTimeout(
      () => {
        if (mode === WebSocketConnection.RTCMode.Video && !this.rtcVideoPeerConnection?.localDescription) {
          console.warn("Cannot handle SDP, renegociating");
          this.videoStatsParser?.stop();
          this.closeRTCVideoPeerConnection();
          this.initVideoRTCPeerConnection();
          this.renegociationVideoFormat = true;
          WebSocketConnection.SendMessageToHost("webrtc_failed");
        }
      },
      videoFormatRenegociationTimeOut ? videoFormatRenegociationTimeOut * 1000 : 3000
    );
    this.SpawnRenegociationTimeout(mode);
    if (mode === WebSocketConnection.RTCMode.Video && this.rtcVideoPeerConnection)
      this.CreateSDPAnswer(this.rtcVideoPeerConnection, sdp, mode);
    else if (mode === WebSocketConnection.RTCMode.Audio && this.rtcAudioPeerConnection)
      this.CreateSDPAnswer(this.rtcAudioPeerConnection, sdp, mode);
  }

  private SpawnRenegociationTimeout(mode: WebSocketConnection.RTCMode): void {
    const videoFormatRenegociationTimeOut = this.VueManager.config?.videoFormatRenegociationTimeOut
      ? this.VueManager.config?.videoFormatRenegociationTimeOut
      : 3;
    const renegociationTimeOut = this.VueManager.config?.renegociationTimeOut
      ? this.VueManager.config?.renegociationTimeOut
      : videoFormatRenegociationTimeOut + 1;
    // set a 4 sec timeout to see if the datachannel is established or if an answer sdp has been created
    this.renegociationTimeout = setTimeout(() => {
      if (!this.renegociationVideoFormat && mode === WebSocketConnection.RTCMode.Video && !this.peerDataChannel) {
        console.warn("Did not establish webrtc DataChannel, renegociating");
        this.videoStatsParser?.stop();
        this.closeRTCVideoPeerConnection();
        this.initVideoRTCPeerConnection();
        WebSocketConnection.SendMessageToHost("renegociate");
      }
    }, Math.max(renegociationTimeOut, videoFormatRenegociationTimeOut + 1) * 1000);
  }

  private async CreateSDPAnswer(
    RTCPeerConnection: RTCPeerConnection,
    sdp: RTCSessionDescriptionInit,
    mode: WebSocketConnection.RTCMode
  ): Promise<void> {
    console.debug(`(WebRTC ${mode}) Received SDP Offer:`, sdp);
    await RTCPeerConnection.setRemoteDescription(sdp);
    console.debug("(WebRTC Video) Successfully set remote description.");
    const locDescr = await RTCPeerConnection.createAnswer();
    await RTCPeerConnection.setLocalDescription(locDescr);
    console.debug(`(WebRTC Video) Sending SDP Answer:`, { sdp: locDescr });
    return WebSocketConnection.SendRTCMessage({ sdp: locDescr }, mode);
  }

  private async HandleRTCIce(
    iceCandidate: RTCIceCandidateInit | RTCIceCandidate,
    mode: WebSocketConnection.RTCMode
  ): Promise<void> {
    if (mode === WebSocketConnection.RTCMode.Video) {
      console.debug(`(WebRTC Video) Received remote Ice Candidate:`, iceCandidate);
      await Utils.delay(100);
      return this.rtcVideoPeerConnection?.addIceCandidate(iceCandidate);
    } else if (mode === WebSocketConnection.RTCMode.Audio) {
      console.debug(`(WebRTC Audio) Received remote Ice Candidate:`, iceCandidate);
      await Utils.delay(100);
      return this.rtcAudioPeerConnection?.addIceCandidate(iceCandidate);
    }
  }

  private SendThroughSocket(evt: TouchEvent | MouseEvent): void {
    const clientWidth = (evt.target as Element).clientWidth;
    const clientHeight = (evt.target as Element).clientHeight;
    let type;
    switch (evt.type) {
      case "mousedown":
        type = "touchstart";
        break;
      case "mousemove":
        type = "touchmove";
        break;
      case "mouseup":
        type = "touchend";
        break;
      default:
        type = evt.type;
        break;
    }

    const touches: Array<{ slot: number; x: number; y: number }> = [];

    if (evt.type.match(/^touch/)) {
      const event = evt as TouchEvent;

      const touchEvts = event.changedTouches || event.touches;

      for (const touchEvt of (touchEvts as unknown) as Touch[]) {
        touches.push({
          slot: touchEvt.identifier,
          x: touchEvt.pageX,
          y: touchEvt.pageY,
        });
      }
    } else {
      const event = evt as MouseEvent;
      touches.push({ slot: 0, x: event.pageX, y: event.pageY });
    }

    const json = {
      clientHeight,
      clientWidth,
      touches,
      // timestamp: Date.now(),
      type,
    };

    this.peerDataChannel?.send(JSON.stringify({ type: "TOUCH", data: json }));
  }

  private TouchStart(evt: TouchEvent | MouseEvent): void {
    this.mouseDown = true;
    this.SendThroughSocket(evt);

    // Needed to prevent touches from messing with the viewport/page.
    evt.preventDefault();
  }

  private TouchMove(evt: TouchEvent | MouseEvent): void {
    if (evt.type.match(/^mouse/) && !this.mouseDown) return;

    if (evt.timeStamp - this.lastTime < (1 / URLParamsManager.TOUCHRATE) * 1000) return;
    this.lastTime = evt.timeStamp;

    this.SendThroughSocket(evt);

    // Needed to prevent touches from messing with the viewport/page.
    evt.preventDefault();
  }

  private TouchEnd(evt: TouchEvent | MouseEvent): void {
    this.mouseDown = false;
    this.SendThroughSocket(evt);

    // Needed to prevent touches from messing with the viewport/page.
    evt.preventDefault();
  }
}
