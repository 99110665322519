export enum RTCStatsType {
  CANDIDATE_PAIR = "candidate-pair",
  LOCAL_CANDIDATE = "local-candidate",
  REMOTE_CANDIDATE = "remote-candidate",
  INBOUND_RTP = "inbound-rtp",
  REMOTE_OUTBOUND_RTP = "remote-outbound-rtp",
  DATA_CHANNEL = "data-channel",
}

export interface RTCStats {
  id: string;
  timestamp: number;
  type: RTCStatsType;
}

export interface RTCIceCandidatePairStatsData extends RTCStats {
  bytesReceived: number;
  bytesSent: number;
  lastPacketReceivedTimestamp: number;
  lastPacketSentTimestamp: number;
  localCandidateId: string;
  nominated: boolean;
  priority: number;
  readable: boolean;
  remoteCandidateId: string;
  selected: boolean;
  state: RTCStatsIceCandidatePairState;
  timestamp: number;
  transportId: string;
  type: RTCStatsType.CANDIDATE_PAIR;
  writable: boolean;
}

export interface RTCDataChannelStats extends RTCStats {
  bytesReceived: number;
  bytesSent: number;
  dataChannelIdentifier: number;
  label: string;
  messagesReceived: number;
  messagesSent: number;
  protocol: string;
  state: RTCDataChannelState;
  type: RTCStatsType.DATA_CHANNEL;
}

export interface RTCIceCandidatePairStats extends RTCIceCandidatePairStatsData {
  localCandidateId: string;
  remoteCandidateId: string;
  transportId: string;
}

export interface RTCIceCandidateStatsData extends RTCStats {
  address: string;
  candidateType: RTCIceCandidateType;
  port: number;
  priority: number;
  protocol: RTCIceProtocol;
  type: RTCStatsType.LOCAL_CANDIDATE | RTCStatsType.REMOTE_CANDIDATE;
}

export interface RTCIceCandidateLocalStats extends RTCIceCandidateStatsData {
  type: RTCStatsType.LOCAL_CANDIDATE;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RTCIceCandidateRemoteStats extends RTCIceCandidateStatsData {
  type: RTCStatsType.REMOTE_CANDIDATE;
}

export interface RTCInboundRTPStreamStats extends RTCStats {
  bytesReceived: number;
  jitter: number;
  kind: "audio" | "video";
  mediaType: "audio" | "video";
  nackCount: number;
  packetsLost: number;
  packetsReceived: number;
  remoteId: string;
  ssrc: number;
  type: RTCStatsType.INBOUND_RTP;
}

export interface RTCInboundRTPAudioStreamStats extends RTCInboundRTPStreamStats {
  fecPacketsDiscarded: number;
  fecPacketsReceived: number;
  kind: "audio";
  mediaType: "audio";
}

export interface RTCInboundRTPVideoStreamStats extends RTCInboundRTPStreamStats {
  bitrateMean: number;
  bitrateStdDev: number;
  discardedPackets: number;
  firCount: number;
  framerateMean: number;
  framerateStdDev: number;
  framesDecoded: number;
  kind: "video";
  mediaType: "video";
  pliCount: number;
}

export interface RTCRemoteOutboundRTPStats extends RTCStats {
  bytesSent: number;
  kind: "audio" | "video";
  localId: string;
  mediaType: "audio" | "video";
  type: RTCStatsType.REMOTE_OUTBOUND_RTP;
  packetsSent: number;
  remoteTimestamp: number;
  ssrc: string;
}

export interface RTCRemoteOutboundRTPVideoStats extends RTCRemoteOutboundRTPStats {
  kind: "video";
  mediaType: "video";
}

export interface RTCRemoteOutboundRTPAudioStats extends RTCRemoteOutboundRTPStats {
  kind: "audio";
  mediaType: "audio";
}
