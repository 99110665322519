import { BrowserType } from "@/old-client-stuff/MobileManager";
import { EventEmitter } from "events";

import {
  RTCCertificateStats,
  RTCCodecStats,
  RTCDataChannelStats,
  RTCIceCandidateLocalStats,
  RTCIceCandidatePairStatsData,
  RTCIceCandidateRemoteStats,
  RTCInboundRTPAudioStreamStatsData,
  RTCInboundRTPVideoStreamStatsData,
  RTCMediaStreamTrackAudioStats,
  RTCMediaStreamTrackVideoStats,
  RTCStats,
  RTCTransportStatsData,
} from "./DefaultRTCStatsTypes";
import { DefaultRawData, DefaultWebRTCStatsParser } from "./DefaultWebRTCStatsParser";
import { FirefoxRawData, FirefoxWebRTCStatsParser } from "./FirefoxWebRTCStatsParser";

type RawData = DefaultRawData | FirefoxRawData;

export interface IceCandidatePair extends RTCIceCandidatePairStatsData {
  localCandidate: RTCIceCandidateLocalStats;
  remoteCandidate: RTCIceCandidateRemoteStats;
  bytesReceivedPerSeconds: number;
}

export interface Transport extends RTCTransportStatsData {
  localCertificate: RTCCertificateStats;
  remoteCertificate: RTCCertificateStats;
  selectedCandidatePair: IceCandidatePair;
}
export interface InboundRTPAudioStream extends RTCInboundRTPAudioStreamStatsData {
  codec: RTCCodecStats;
  track: RTCMediaStreamTrackAudioStats;
  transport: Transport;
}

export interface InboundRTPVideoStream extends RTCInboundRTPVideoStreamStatsData {
  codec: RTCCodecStats;
  track: RTCMediaStreamTrackVideoStats;
  transport: Transport;
}

export interface ParsedWebRTCStats {
  timestamp: number;
  date: Date;
  rawData?: RawData;
  inboundRTPAudioStream?: InboundRTPAudioStream;
  inboundRTPVideoStream?: InboundRTPVideoStream;
  dataChannel?: RTCDataChannelStats;
}

export declare interface WebRTCStatsParser extends EventEmitter {
  on(event: "rawStats", listener: (rawStats: RTCStats[]) => void): this;
  on(event: "stats", listener: (stats: ParsedWebRTCStats) => void): this;
}

export interface IStatsParser {
  on(event: "stats", listener: (stats: ParsedWebRTCStats) => void): this;

  parse(rawStats: RTCStats[]): void;
}

export class WebRTCStatsParser extends EventEmitter {
  private readonly rtcPeerConnection: RTCPeerConnection;
  private readonly parser: IStatsParser;
  private readonly delay: number = 1000;
  private readonly historyLimit = 100;
  private readonly rawHistory: RTCStats[][];

  private intervalHandle: number;

  constructor(rtcPeerConnection: RTCPeerConnection, delay = 1000, browserType: BrowserType, ping: number) {
    super();
    this.rtcPeerConnection = rtcPeerConnection;
    this.delay = delay;
    this.rawHistory = [];
    if (browserType === BrowserType.FIREFOX) this.parser = new FirefoxWebRTCStatsParser(ping);
    else this.parser = new DefaultWebRTCStatsParser();

    this.parser.on("stats", (stats: ParsedWebRTCStats) => this.emit("stats", stats));
    this.start();
  }

  public start(): void {
    this.intervalHandle = setInterval(() => this.run(), this.delay);
  }

  public stop(): void {
    clearInterval(this.intervalHandle);
  }

  private async run(): Promise<void> {
    if (!this.rtcPeerConnection) return;
    if (this.rtcPeerConnection.connectionState !== "connected") return;

    const report = await this.rtcPeerConnection.getStats();
    this.rawHistory.unshift(Array.from(report.values()));

    if (this.rawHistory.length > this.historyLimit) this.rawHistory.pop();

    this.emit("rawStats", this.rawStats);

    this.parser.parse(this.rawStats);
  }

  public get rawStats(): RTCStats[] {
    return this.rawHistory[0];
  }
}
