/* eslint-disable @typescript-eslint/no-explicit-any */
export enum State {
  NONE = "",
  LOADER = "LOADER",
  PLAYAREA = "PLAYAREA",
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChangeState(VueManager: any, state: State): void {
  VueManager.currentState = state.toString();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function GetCurrentState(VueManager: any): State {
  return VueManager.currentState;
}
