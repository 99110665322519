/* eslint-disable @typescript-eslint/no-explicit-any */
import Uid from "uniqid";

// import * as GameDataManager from "./GameDataManager";
import ClientSpeedTest from "./ClientSpeedTest";
import * as MobileManager from "./MobileManager";
import * as WebSocketConnection from "./WebSocketConnection";
import * as Stats from "./Stat";
import * as StateManager from "./StateManager";
import * as URLParamsManager from "./URLParamsManager";
import * as Utils from "./Utils";
import WebRTCController from "./WebRTCController";
import { axiosInstance } from "../store";
import { ClientConfig } from "./Config";

declare let adapter: unknown;

export default class App {
  private static readonly TIMEOUT_DURATION = 90;

  public statId: string;
  private hostId: string;

  public webrtcController: WebRTCController;
  private videoElement: HTMLVideoElement;

  private readonly speedTest: ClientSpeedTest;
  private onReadyPromise: Promise<void>;
  private timeouted: boolean;

  private VueManager: any;
  private timeoutTimer: number;
  private endpointHost: string;

  private hostCheckTask: number;
  private hostLastSeen: Date;
  private onVisibilitychangeFunction: () => void;

  private onVisibilitychange(): void {
    if (document.visibilityState === "hidden") this.videoElement.pause();
    else if (document.visibilityState === "visible") {
      const StatLeave: Stats.StatLeave = {
        date: Stats.GetDate(),
        entrypoint_ip: window.location.host,
        game: this.VueManager.game.appID,
        host_id: "",
        log_type: "client_end_session_stat",
        reason_of_leave: "unfocus",
        stat_id: this.statId,
        url: window.location.href,
        username: this.VueManager.$store.state.authentication.user.username,
      };
      this.Close();
      console.warn("Unfocus AFK triggered.");
      Stats.SendStats(StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.unfocus"));
    }
  }

  constructor(VueManager: any) {
    this.VueManager = VueManager;
    StateManager.ChangeState(VueManager, StateManager.State.LOADER);
    (document.querySelector<HTMLDivElement>("div#playView") as HTMLDivElement).style.display = "block";
    this.videoElement = document.querySelector("video") as HTMLVideoElement;

    this.onVisibilitychangeFunction = this.onVisibilitychange.bind(this);
    document.addEventListener("visibilitychange", this.onVisibilitychangeFunction);

    this.speedTest = new ClientSpeedTest(this.VueManager.$store.state.apiEndpoint);

    window.addEventListener("resize", () => (VueManager.orientation = MobileManager.getMobileOrientation()));
  }

  public async Init(): Promise<void> {
    this.statId = Uid();
    await this.GetConfig();
    this.SetupWebRTCController();

    if (!this.CheckValidBrowserAndOS()) {
      Stats.SendStats({
        date: Stats.GetDate(),
        entrypoint_ip: window.location.host,
        game: this.VueManager.game.appID,
        host_id: "none",
        log_type: "client_end_session_stat",
        reason_of_leave: "incompatible_browser",
        stat_id: this.statId,
        url: window.location.href,
        username: this.VueManager.$store.state.authentication.user.username,
      } as Stats.StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.bad-browser"));
      return;
    }

    const StatST = {
      date: Stats.GetDate(),
      entrypoint_ip: window.location.host,
      log_type: "client_speedtest_stat",
      speed_test_pass: "",
      result: {},
      stat_id: this.statId,
      url: window.location.href,
      username: this.VueManager.$store.state.authentication.user.username,
    };

    await this.VueManager.$store.direct.dispatch.games.getGames();
    await this.VueManager.$store.direct.dispatch.games.getGenres();
    await this.VueManager.$store.direct.dispatch.games.getPublishers();
    await this.VueManager.$store.direct.dispatch.games.getHomeSections();
    await this.VueManager.$store.direct.dispatch.games.getGameLists();

    this.speedTest.LaunchSpeedTest();

    if (this.VueManager.game) this.VueManager.$store.direct.commit.games.ADD_RECENT_GAME(this.VueManager.game);
    this.endpointHost = new URL(this.VueManager.$store.state.apiEndpoint).host;

    this.ConnectWebSocket();
    try {
      const result = await this.speedTest.speedTestResult;
      StatST.speed_test_pass = "pass";
      const statsSp = this.speedTest.resultForStats(result);
      StatST.result = statsSp;
      this.VueManager.speedtestResultPing = statsSp.pingResult;
      this.VueManager.speedtestResultDl = statsSp.dlResult;
      Stats.SendStats(StatST);
      WebSocketConnection.SendSpeedTestResult(statsSp);
    } catch (result) {
      // Fail / timeout / skiped
      console.warn("Speedtest has failed.");
      StatST.speed_test_pass = "fail";
      StatST.result = result !== null ? this.speedTest.resultForStats(result) : {};
      Stats.SendStats(StatST);
      this.Alert(this.VueManager.$t("play.alerts.speedtest-fail"));
      this.Close();
    }
  }

  public Restart(): void {
    console.warn("Restarting...");
    this.Close();
    this.Init();
  }

  /**
   * Launch a mobile app.
   * @param app The app id to launch.
   */
  public LaunchApp(_autoLaunch = false): void {
    try {
      StateManager.ChangeState(this.VueManager, StateManager.State.LOADER);

      this.VueManager.fabDirection = this.VueManager.game.fabDirection || "bottom";
      try {
        const buttonsStyle = this.VueManager.game.buttonsStyle;
        Object.entries({
          fab: "div.v-speed-dial",
          timer: "div#trialTimer",
        }).forEach((kp) => {
          const [key, selector] = kp;
          if (!buttonsStyle[key]) return;
          const buttonElem = document.querySelector(selector) as HTMLElement;
          if (!buttonElem) return;
          const newLocal: { [property: string]: string } = buttonsStyle[key];
          Object.entries(newLocal).forEach((kp2) => {
            const [property, value] = kp2;
            console.debug(`(App) Setting style property ${property}:${value} for ${key} element (${selector}).`);
            buttonElem.style.setProperty(property, value);
          });
        });
      } catch (err) {
        console.error(err);
      }

      this.timeouted = false;
      this.timeoutTimer = setTimeout(() => {
        if (StateManager.GetCurrentState(this.VueManager) === StateManager.State.PLAYAREA) return;
        this.Close();
        this.timeouted = true;
        console.error("Connection was taking too long.");
        Stats.SendStats({
          date: Stats.GetDate(),
          entrypoint_ip: window.location.host,
          game: this.VueManager.game.appID,
          host_id: this.hostId,
          log_type: "client_end_session_stat",
          reason_of_leave: "webrtc_timeout",
          stat_id: this.statId,
          url: window.location.href,
          username: this.VueManager.$store.state.authentication.user.username,
        } as Stats.StatLeave);
        this.Alert(this.VueManager.$t("play.alerts.connection-too-long"));
      }, 1000 * App.TIMEOUT_DURATION);
    } catch (err) {
      console.error(err);
    }
  }

  private async GetConfig(): Promise<void> {
    try {
      const response = await axiosInstance.get<ClientConfig>("/api/v1/client_config");
      if (response.status === 200) this.VueManager.config = response.data;
    } catch (e) {
      console.error(e);
    }
  }

  private CheckValidBrowserAndOS(): boolean {
    if (URLParamsManager.DEBUG) return true;
    const os = MobileManager.getMobileOS();
    const br = MobileManager.getBrowserType();
    this.VueManager.browserType = MobileManager.getBrowserType();
    console.debug(`Playing on ${MobileManager.BrowserType[br]} ${MobileManager.MobileOS[os]}`);
    if (os === MobileManager.MobileOS.ANDROID) {
      switch (br) {
        case MobileManager.BrowserType.CHROME:
        case MobileManager.BrowserType.CHROME_WEBVIEW:
        case MobileManager.BrowserType.EDGE:
        case MobileManager.BrowserType.FACEBOOK:
        case MobileManager.BrowserType.FIREFOX:
        case MobileManager.BrowserType.OPERA:
        case MobileManager.BrowserType.SAMSUNG:
          return true;
        default:
          return false;
      }
    }
    if (os === MobileManager.MobileOS.IOS) {
      switch (br) {
        case MobileManager.BrowserType.SAFARI:
        case MobileManager.BrowserType.FACEBOOK:
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  private ConnectWebSocket(): void {
    const StatLeave = this.GenerateStatLeave("");
    WebSocketConnection.Init(this.VueManager.$store.state.apiEndpoint, this.endpointHost);
    WebSocketConnection.SetOnConnect(() => {
      WebSocketConnection.PeerWithHost(URLParamsManager.HOST, this.VueManager.game.appID, this.VueManager.game._id);
    });

    WebSocketConnection.SetOnNoHostAvailable(() => {
      console.error("No host available.");
      this.Alert(this.VueManager.$t("play.alerts.full"));
    });

    WebSocketConnection.SetOnVideoRTCMessage((msg) => {
      if (!this.webrtcController) {
        this.SetupWebRTCController();
        (this.webrtcController as WebRTCController).StartVideoWebRTC(this.videoElement);
      }
      if (!this.webrtcController.webRTCId) {
        this.webrtcController.setWebRTCId(WebSocketConnection.GetWebRTCId());
      }
      // Reset the videotrack if we receive a new webrtc_id (we renegociate)
      if (this.webrtcController.webRTCId !== WebSocketConnection.GetWebRTCId()) {
        this.webrtcController.setWebRTCId(WebSocketConnection.GetWebRTCId());
        this.videoElement.srcObject = null;
      }
      this.webrtcController.HandleRTCMessage(msg, WebSocketConnection.RTCMode.Video).catch((e) => {
        console.error("(WebRTC Video) Error while processing RTC message: ", e);
      });
    });
    WebSocketConnection.SetOnAudioRTCMessage((msg) => {
      if (!this.webrtcController) {
        this.SetupWebRTCController();
        (this.webrtcController as WebRTCController).StartVideoWebRTC(this.videoElement);
      }
      if (!this.webrtcController.webRTCId) {
        this.webrtcController.setWebRTCId(WebSocketConnection.GetWebRTCId());
      }
      this.webrtcController.HandleRTCMessage(msg, WebSocketConnection.RTCMode.Audio).catch((e) => {
        console.error("(WebRTC Audio) Error while processing RTC message: ", e);
      });
    });
    WebSocketConnection.SetOnHostFound((hostId: string) => {
      console.log("(Signaling) Paired with " + hostId);
      this.hostId = hostId;
      this.webrtcController.SetHostId(hostId);
      StatLeave.host_id = this.hostId;

      this.LaunchApp();
    });
    WebSocketConnection.SetOnForwardError((e) => {
      console.error(e);
      this.Close();
      StatLeave.reason_of_leave = "host_forward_error";
      Stats.SendStats(StatLeave);
      this.Alert(URLParamsManager.DEBUG ? e.message : this.VueManager.$t("play.alerts.host-error"));
    });
    WebSocketConnection.SetOnError((e) => {
      console.error(e);
      this.Close();
      StatLeave.reason_of_leave = "negociator_error";
      Stats.SendStats(StatLeave);
      this.Alert(URLParamsManager.DEBUG ? e.message : this.VueManager.$t("play.alerts.server-error"));
    });
    WebSocketConnection.SetOnRestoreSaveError(() => {
      console.error("Unable to restore save");
      this.Close();
      StatLeave.reason_of_leave = "host_restore_save_error";
      Stats.SendStats(StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.save-restore-fail"));
    });
    WebSocketConnection.SetOnPeerDisconnected(() => {
      this.Close();
      console.warn("Peer disconnected.");
      StatLeave.reason_of_leave = "host_disconnect";
      Stats.SendStats(StatLeave);
      this.Alert(URLParamsManager.DEBUG ? "Host disconnected." : this.VueManager.$t("play.alerts.host-disconnected"));
    });
    WebSocketConnection.SetOnAFK(() => {
      this.Close();
      console.warn("AFK triggered.");
      StatLeave.reason_of_leave = "afk";
      Stats.SendStats(StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.afk"));
    });
    WebSocketConnection.SetOnTimeout(() => {
      this.Close();
      console.warn("Timeout triggered.");
      StatLeave.reason_of_leave = "timeout";
      Stats.SendStats(StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.play-to-much"));
    });
    WebSocketConnection.SetOnTrialEnd(() => {
      this.Close();
      console.warn("Trial Ended.");
      StatLeave.reason_of_leave = "trial_end";
      Stats.SendStats(StatLeave);
      this.VueManager.back(true);
    });
    WebSocketConnection.SetOnGameEnd((_win: boolean | undefined) => {
      this.Close();
      StatLeave.reason_of_leave = "trial_end";
      Stats.SendStats(StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.trial-end", { game: this.VueManager.game.name }));
    });
    WebSocketConnection.SetOnLoadingProgress((data: { stage: number; total: number; message: string }) => {
      console.debug(`(Host) Received loading progress: ${data.stage} / ${data.total}: ${data.message}`);
      this.VueManager.progress = (data.stage / data.total) * 100;
    });
    WebSocketConnection.SetOnSpeedTestFail(() => {
      this.Close();
      StatLeave.reason_of_leave = "speedtest_fail";
      Stats.SendStats(StatLeave);
      this.Alert(this.VueManager.$t("play.alerts.speedtest-fail"));
    });
    WebSocketConnection.SetOnDataStop(() => {
      this.webrtcController.DataStop();
    });
    WebSocketConnection.SetOnNetworkState((state: number) => {
      this.VueManager.networkStrengh = state;
    });
    WebSocketConnection.SetOnUserAlreadyPaired(() => {
      console.log(`Cannot start a new session as a session is currently ongoing.`);
      this.Alert(this.VueManager.$t("play.alerts.user-already-paired"));
    });
    WebSocketConnection.SetOnPreviousSavePending(() => {
      console.log(`Cannot stat a new session, previous save not retrieved.`);
      this.Alert(this.VueManager.$t("play.alerts.previous-save-pending"));
    });
    this.onReadyPromise = new Promise((res) => {
      if (URLParamsManager.DEBUG) {
        console.debug("DEBUG mode, firing ready automaticall.");
        res();
      }
      WebSocketConnection.SetOnReady(() => {
        console.log("Received ready");
        Utils.delay(750).then(res);
        this.CheckHost();
      });
    });
    WebSocketConnection.SetOnWebSocketClose((_e: CloseEvent) => {
      console.log("WebSocket Closed !");
      clearInterval(this.hostCheckTask);
      this.VueManager.$store.direct.commit.SET_HOST_LAST_SEEN(undefined);
    });
  }

  private SetupWebRTCController(): void {
    this.webrtcController = new WebRTCController(this.VueManager, this.statId);
    this.webrtcController.InitTouch(this.videoElement);
    this.webrtcController.StartVideoWebRTC(this.videoElement);
    this.webrtcController.OnConnect = () => {
      this.StartGame();
    };
    this.webrtcController.OnFrame = () => {
      this.ShowVideo();
    };
    this.webrtcController.OnStat = () => {
      this.hostLastSeen = new Date();
    };
  }

  private async StartGame(): Promise<void> {
    this.webrtcController.SendLaunchApp(this.VueManager.game.appID);
    if (!this.speedTest.speedTestResult) this.speedTest.LaunchSpeedTest();

    console.log("Everything ready, waiting for READY to show video..");
    await this.onReadyPromise;
    if (this.timeouted) return;
    this.VueManager.muted = true;
    this.videoElement.muted = true;
    this.videoElement.play();
  }

  private async ShowVideo(): Promise<void> {
    await this.onReadyPromise;
    console.log("Showing video.");
    StateManager.ChangeState(this.VueManager, StateManager.State.PLAYAREA);
    this.videoElement.pause();
    this.VueManager.muted = false;
    this.videoElement.muted = false;
    this.videoElement.play().catch((err) => {
      console.error("Couldn't launch video. Muting video and trying again", err);
      this.VueManager.muted = true;
      this.videoElement.muted = true;
      window.setTimeout(() => this.videoElement.play(), 250);
    });

    if (this.VueManager.$store.direct.state.authentication.user.temporaryAccount) {
      const trialTimeout = this.VueManager?.game.trialTimeout;
      if (trialTimeout) {
        this.VueManager.endTrialDate = new Date(Date.now() + 1000 * trialTimeout);
        this.VueManager.showTimer = true;
      }
    }
  }

  public Close(): void {
    window.clearTimeout(this.timeoutTimer);
    document.removeEventListener("visibilitychange", this.onVisibilitychangeFunction);
    this.webrtcController.Close();
    WebSocketConnection.Close();
  }

  private Alert(message: string, title = ""): void {
    this.VueManager.$router.back();
    this.VueManager.$store.direct.commit.SHOW_ALERT({
      title,
      text: message,
    });
    this.Close();
    StateManager.ChangeState(this.VueManager, StateManager.State.NONE);
  }

  private GenerateStatLeave(reason: string): Stats.StatLeave {
    return {
      date: Stats.GetDate(),
      entrypoint_ip: window.location.host,
      game: this.VueManager.game.appID,
      host_id: "",
      log_type: "client_end_session_stat",
      reason_of_leave: reason,
      stat_id: this.statId,
      url: window.location.href,
      username: this.VueManager.$store.state.authentication.user.username,
    };
  }

  private CheckHost(): void {
    if (this.hostCheckTask) return;
    this.hostCheckTask = setInterval(() => {
      const now = new Date();
      if (
        this.VueManager.$store.direct.state.lastSeenHost !== undefined &&
        (now.getTime() - this.VueManager.$store.direct.state.lastSeenHost.getTime()) / 1000 >
          (this.VueManager.config.hostVideoTimeOut ? this.VueManager.config.hostVideoTimeOut : 5)
      ) {
        const StatLeave = this.GenerateStatLeave("host_video_lost");
        Stats.SendStats(StatLeave);
        this.Alert(this.VueManager.$t("play.alerts.host-video-lost"));
      }
    }, 1000);
  }
}
