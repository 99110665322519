









































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Header from "@/components/Header.vue";

import { Game } from "@/store/modules/games";

@Component({
  components: {
    Header,
  },
})
export default class PleaseLogin extends Vue {
  @Prop(Boolean) dialog: boolean;
  @Prop(String) title: string;
  @Prop(Object) game: Game;

  get show(): boolean {
    return this.dialog;
  }

  goLogin(): void {
    this.$store.direct.commit.SHOW_LOGIN_POPUP({
      onLogin: () => {
        this.$emit("close");
      },
    });
  }
}
