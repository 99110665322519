export default {
  peerConnectionConfig: {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      { urls: "stun:stun.stunprotocol.org:3478" },
      {
        urls: "turn:54.255.176.216:3478",
        username: "caregame",
        credential: "caregame",
      },
    ],
  },
};

export interface ClientConfig {
  name: string;
  renegociationTimeOut: number;
  videoFormatRenegociationTimeOut: number;
  iceServers: RTCIceServer[];
}
