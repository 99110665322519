import { render, staticRenderFns } from "./PlayOverlayMenu.vue?vue&type=template&id=5ed4386e&scoped=true&"
import script from "./PlayOverlayMenu.vue?vue&type=script&lang=ts&"
export * from "./PlayOverlayMenu.vue?vue&type=script&lang=ts&"
import style0 from "./PlayOverlayMenu.vue?vue&type=style&index=0&id=5ed4386e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed4386e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
installComponents(component, {VBadge,VBtn,VDivider,VIcon,VSpeedDial})
