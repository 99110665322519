/* eslint-disable @typescript-eslint/no-explicit-any */
import Store from "../store";
import { SpeedTestResultForStats } from "./ClientSpeedTest";
import * as Utils from "./Utils";

interface WebSocketMessage {
  signal:
    | "signaling_error"
    | "peer_disconnected"
    | "host_found"
    | "rtc_message"
    | "forward"
    | "ping"
    | "no_host_available"
    | "network_state"
    | "loading_progress"
    | "user_already_paired"
    | "previous_save_pending";
  data: unknown;
}

interface SignalingError extends WebSocketMessage {
  signal: "signaling_error";
  data: string;
}

interface PeerDisconnected extends WebSocketMessage {
  signal: "peer_disconnected";
  data: never;
}

interface HostFound extends WebSocketMessage {
  signal: "host_found";
  data: { host_name: string };
}

interface NoHostAvailable extends WebSocketMessage {
  signal: "no_host_available";
  data: never;
}

export enum RTCMode {
  Audio = "Audio",
  Video = "Video",
}

interface RTCMessage extends WebSocketMessage {
  signal: "rtc_message";
  data: {
    id: string;
    type: RTCMode;
    rtc_message:
      | {
          ice: RTCIceCandidateInit | RTCIceCandidate;
        }
      | {
          sdp: RTCSessionDescriptionInit;
        };
  };
}

interface RTCIceMessage extends RTCMessage {
  data: {
    id: string;
    type: RTCMode;
    rtc_message: {
      ice: RTCIceCandidateInit | RTCIceCandidate;
    };
  };
}

interface NetworkState extends WebSocketMessage {
  signal: "network_state";
  data: number;
}

interface LoadingProgress extends WebSocketMessage {
  signal: "loading_progress";
  data: {
    stage: number;
    total: number;
    message: string;
  };
}

interface UserAlreadyPaired extends WebSocketMessage {
  signal: "user_already_paired";
  data: never;
}

interface PreviousSavePending extends WebSocketMessage {
  signal: "previous_save_pending";
  data: never;
}

function isIRTCIceMessage(arg: any): arg is RTCIceMessage {
  return arg.data.rtc_message.ice !== undefined;
}

interface RTCSdpMessage extends RTCMessage {
  data: {
    id: string;
    type: RTCMode;
    rtc_message: {
      sdp: RTCSessionDescriptionInit;
    };
  };
}

function isIRTCSdpMessage(arg: any): arg is RTCSdpMessage {
  return arg.data.rtc_message.sdp !== undefined;
}

type ForwardMessage =
  | "AFK"
  | "TIMEOUT"
  | "TRIAL_END"
  | "READY"
  | "WIN"
  | "LOSE"
  | "END"
  | "ERROR"
  | "RESTORE_SAVE_ERROR"
  | "SPEEDTEST_FAIL"
  | "DATA_STOP";

interface Forward extends WebSocketMessage {
  signal: "forward";
  data: ForwardMessage;
}

interface Ping extends WebSocketMessage {
  signal: "ping";
  data: void;
}

export type ICEorSDP = { candidate: RTCIceCandidateInit | RTCIceCandidate } | RTCSessionDescriptionInit;

let onError: (e: Error) => void;
let onForwardError: (e: Error) => void;
let onRestoreSaveError: () => void;
let onConnect: () => void;
let onPeerDisconnected: () => void;
let onHostFound: (hostId: string) => void;
let onNoHostAvailable: () => void;
let onVideoRTCMessage: (msg: ICEorSDP) => void;
let onAudioRTCMessage: (msg: ICEorSDP) => void;
let onAFK: () => void;
let onTimeout: () => void;
let onTrialEnd: () => void;
let onReady: () => void;
let onGameEnd: (win: boolean | undefined) => void;
let onNetworkState: (state: number) => void;
let onSpeedTestFail: () => void;
let onDataStop: () => void;
let onLoadingProgress: (data: { stage: number; total: number; message: string }) => void;
let onUserAlreadyPaired: () => void;
let onPreviousSavePending: () => void;
let onWebSocketClose: (e: CloseEvent) => void;

let websocket: WebSocket;
let currentRTCID: string | undefined = undefined;
const oldRTCIDs: string[] = [];

export function SetOnError(cb: (e: Error) => void): void {
  onError = cb;
}

export function SetOnForwardError(cb: (e: Error) => void): void {
  onForwardError = cb;
}

export function SetOnRestoreSaveError(cb: () => void): void {
  onRestoreSaveError = cb;
}

export function SetOnConnect(cb: () => void): void {
  onConnect = cb;
}

export function SetOnPeerDisconnected(cb: () => void): void {
  onPeerDisconnected = cb;
}

export function SetOnHostFound(cb: (hostId: string) => void): void {
  onHostFound = cb;
}

export function SetOnNoHostAvailable(cb: () => void): void {
  onNoHostAvailable = cb;
}

export function SetOnVideoRTCMessage(cb: (msg: ICEorSDP) => void): void {
  onVideoRTCMessage = cb;
}

export function SetOnAudioRTCMessage(cb: (msg: ICEorSDP) => void): void {
  onAudioRTCMessage = cb;
}

export function SetOnAFK(cb: () => void): void {
  onAFK = cb;
}

export function SetOnTimeout(cb: () => void): void {
  onTimeout = cb;
}

export function SetOnTrialEnd(cb: () => void): void {
  onTrialEnd = cb;
}

export function SetOnReady(cb: () => void): void {
  onReady = cb;
}

export function SetOnGameEnd(cb: (win: boolean | undefined) => void): void {
  onGameEnd = cb;
}

export function SetOnNetworkState(cb: (state: number) => void): void {
  onNetworkState = cb;
}

export function SetOnSpeedTestFail(cb: () => void): void {
  onSpeedTestFail = cb;
}

export function SetOnDataStop(cb: () => void): void {
  onDataStop = cb;
}

export function SetOnLoadingProgress(cb: (data: { stage: number; total: number; message: string }) => void): void {
  onLoadingProgress = cb;
}

export function SetOnUserAlreadyPaired(cb: () => void): void {
  onUserAlreadyPaired = cb;
}

export function SetOnPreviousSavePending(cb: () => void): void {
  onPreviousSavePending = cb;
}

export function SetOnWebSocketClose(cb: (e: CloseEvent) => void): void {
  onWebSocketClose = cb;
}

function SignalingError(message: SignalingError): void {
  console.error(message.data);
}

function PeerDisconnected(_message: PeerDisconnected): void {
  console.error("Host disconnected");
  if (onPeerDisconnected) onPeerDisconnected();
}

function HostFound(message: HostFound): void {
  console.log("(WebSocket) Found host: " + message.data.host_name + ". Starting WebRTC protocol.");
  if (onHostFound) onHostFound(message.data.host_name);
}

function NoHostAvailable(): void {
  if (onNoHostAvailable) onNoHostAvailable();
}

function NetworkState(message: NetworkState): void {
  if (onNetworkState) onNetworkState(message.data);
}

function LoadingProgress(message: LoadingProgress): void {
  if (onLoadingProgress) onLoadingProgress(message.data);
}

function UserAlreadyPaired(): void {
  if (onUserAlreadyPaired) onUserAlreadyPaired();
}

function PreviousSavePending(): void {
  if (onPreviousSavePending) onPreviousSavePending();
}

export function DropCurrentRTCID(): void {
  if (currentRTCID) oldRTCIDs.push(currentRTCID);
  currentRTCID = undefined;
}

function RTCMessage(message: RTCMessage): void {
  if (oldRTCIDs.includes(message.data.id)) {
    console.warn(`(WebSocket) Received RTC Message with an old ID. Dropping it.`);
    return;
  }
  if (message.data.id !== currentRTCID) {
    console.log(`(WebSocket) Received RTC Message with a new ID.`);
    DropCurrentRTCID();
    currentRTCID = message.data.id;
  }

  let msg: ICEorSDP;
  if (isIRTCSdpMessage(message)) msg = message.data.rtc_message.sdp;
  else if (isIRTCIceMessage(message)) msg = { candidate: message.data.rtc_message.ice };
  else throw new Error("RTC Message had a wrong type.");
  if (onVideoRTCMessage && message.data.type === RTCMode.Video) onVideoRTCMessage(msg);
  else if (onAudioRTCMessage && message.data.type === RTCMode.Audio) onAudioRTCMessage(msg);
}

function Forward(message: Forward): void {
  console.debug("Received message from the Host:", message);
  switch (message.data) {
    case "AFK":
      return onAFK?.();
    case "TIMEOUT":
      return onTimeout?.();
    case "TRIAL_END":
      return onTrialEnd?.();
    case "READY":
      return onReady?.();
    case "WIN":
      return onGameEnd?.(true);
    case "LOSE":
      return onGameEnd?.(false);
    case "END":
      return onGameEnd?.(undefined);
    case "RESTORE_SAVE_ERROR":
      return onRestoreSaveError?.();
    case "ERROR":
      return onForwardError?.(new Error("Received ERROR message from Host"));
    case "SPEEDTEST_FAIL":
      return onSpeedTestFail?.();
    case "DATA_STOP":
      return onDataStop?.();
    default:
      return Utils.assertNever(message.data);
  }
}

function Ping(_: Ping): void {
  // console.debug("(WebSocket) Received PING");
  websocket.send(JSON.stringify({ signal: "pong" }));
}

export function Close(): void {
  websocket?.close();
}

export function Init(apiEndpoint: string, endpointIP: string): void {
  const wsProtocol = new URL(apiEndpoint).protocol === "https:" ? "wss" : "ws";
  const host = endpointIP;
  const origin = `${wsProtocol}://${host}`;
  console.debug(`(WebSocket) Attempting to connect to WebSocket on ${origin}/${Store.getters.authentication.token}`);
  websocket = new WebSocket(`${origin}/${Store.getters.authentication.token}`);
  websocket.onerror = (error: any) => {
    console.error(
      `(WebSocket) Unable to connect to ${origin}/${Store.getters.authentication.token}. Are you sure a WebSocket server is running there ?`,
      error
    );
    onError?.(new Error(`Unable to connect to WebSocket on ${origin}/${Store.getters.authentication.token}`));
    Close();
  };

  websocket.onopen = () => {
    console.log("(WebSocket) Connected to WebSocket");
    if (onConnect) onConnect();
  };

  websocket.onmessage = (event): void => {
    const message:
      | SignalingError
      | PeerDisconnected
      | HostFound
      | NoHostAvailable
      | RTCMessage
      | Forward
      | Ping
      | NetworkState
      | LoadingProgress
      | UserAlreadyPaired
      | PreviousSavePending = JSON.parse(event.data);
    switch (message.signal) {
      case "signaling_error":
        return SignalingError(message);
      case "peer_disconnected":
        return PeerDisconnected(message);
      case "host_found":
        return HostFound(message);
      case "no_host_available":
        return NoHostAvailable();
      case "rtc_message":
        return RTCMessage(message);
      case "forward":
        return Forward(message);
      case "ping":
        return Ping(message);
      case "network_state":
        return NetworkState(message);
      case "loading_progress":
        return LoadingProgress(message);
      case "user_already_paired":
        return UserAlreadyPaired();
      case "previous_save_pending":
        return PreviousSavePending();
      default:
        return Utils.assertNever(message);
    }
  };

  websocket.onclose = (e) => {
    console.warn("(WebSocket) Disconnected from WebSocket.", e);
    if (onWebSocketClose) onWebSocketClose(e);
  };
}

export function PeerWithHost(host: string, game: string, gameId: string): void {
  const innerSize: { height: number; width: number } = {
    height: window.innerHeight,
    width: window.innerWidth,
  };

  const screenSize: { height: number; width: number } = {
    height: screen.height,
    width: screen.width,
  };

  websocket.send(
    JSON.stringify({
      signal: "client_connect",
      data: { hostName: host, innerSize, screenSize, game, gameId },
    })
  );
}

export function SendRTCMessage(
  rtcMessage: { ice: RTCIceCandidate } | { sdp: RTCSessionDescriptionInit },
  mode: RTCMode
): void {
  console.debug("(WebSocket) Sending a RTC Message");
  websocket.send(
    JSON.stringify({
      signal: "rtc_message",
      data: { id: currentRTCID, type: mode, rtc_message: rtcMessage },
    })
  );
}

export function SendMessageToHost(msg: string): void {
  console.debug("(WebSocket) Forwarding a message to the Host" + msg);
  websocket.send(JSON.stringify({ signal: "forward", data: msg }));
}

export function GetWebRTCId(): string | undefined {
  return currentRTCID;
}

export function SendSpeedTestResult(result: SpeedTestResultForStats): void {
  console.debug("(WebSocket) Forwarding a speedtest result to the Host");
  const data = {
    pingResult: result.adjustedPing,
    dlResult: result.dlResult,
    jitterResult: result.adjustedJitter,
  };
  websocket.send(JSON.stringify({ signal: "speed_test_result", data }));
}
