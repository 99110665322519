
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as d3 from "d3-format";
import locale from "d3-format/locale/en-US.json";

d3.formatDefaultLocale(locale as d3.FormatLocaleDefinition);

import { ParsedWebRTCStats, IceCandidatePair } from "@/WebRTC/WebRTCStatsParser";
import { UserPermission } from "../store/modules/authentication";
import { RTCMediaStreamTrackStats } from "@/WebRTC/DefaultRTCStatsTypes";

interface Node {
  name: string;
  children?: Node[];
}

const delayFormat = "04,.4r";
const bandwidthFormat = ",.4r";
const fpsFormat = "02,.1r";
const numberFormat = ",";

@Component
export default class TheWebRTCVideoStats extends Vue {
  @Prop()
  readonly webrtcVideoStats: ParsedWebRTCStats | null = null;
  @Prop()
  readonly webrtcAudioStats: ParsedWebRTCStats | null = null;

  items(): Node[] {
    if (this.$store.direct.getters.authentication.hasPermission(UserPermission.SEE_BASIC_WEBRTC_STATS))
      return this.basicStats();
    else if (this.$store.direct.getters.authentication.hasPermission(UserPermission.SEE_FULL_WEBRTC_STATS))
      return this.advancedStats();
    else return [];
  }

  basicStats(): Node[] {
    const items: Node[] = [];
    if (this.webrtcVideoStats && this.webrtcVideoStats.inboundRTPVideoStream) {
      items.push({
        name: `Ping: ${d3.format(delayFormat)(
          this.getPing(this.webrtcVideoStats.inboundRTPVideoStream.transport.selectedCandidatePair)
        )} ms`,
      });
      items.push({
        name: `Buffer Delay: ${d3.format(delayFormat)(
          (this.webrtcVideoStats.inboundRTPVideoStream.track.jitterBufferDelay /
            this.webrtcVideoStats.inboundRTPVideoStream.track.jitterBufferEmittedCount) *
            1000 +
            this.webrtcVideoStats.inboundRTPVideoStream.transport.selectedCandidatePair.currentRoundTripTime * 1000
        )} ms`,
      });
      items.push({
        name: `Resolution: ${this.webrtcVideoStats.inboundRTPVideoStream.track.frameWidth}x${this.webrtcVideoStats.inboundRTPVideoStream.track.frameHeight}`,
      });
      items.push({
        name: `FPS: ${d3.format(fpsFormat)(
          this.webrtcVideoStats.inboundRTPVideoStream.framesDecoded /
            this.webrtcVideoStats.inboundRTPVideoStream.totalInterFrameDelay
        )} `,
      });
      items.push({
        name: `Datarate: ${d3.format(bandwidthFormat)(
          this.getDatarate(this.webrtcVideoStats.inboundRTPVideoStream.transport.selectedCandidatePair) * 8
        )} kbps`,
      });
    }
    return items;
  }

  advancedStats(): Node[] {
    const items: Node[] = [];
    if (this.webrtcVideoStats && this.webrtcVideoStats.inboundRTPVideoStream) {
      items.push({
        name: "Video Stats",
        children: [
          {
            name: `Codec: ${this.webrtcVideoStats.inboundRTPVideoStream.codec.mimeType}`,
          },
          {
            name: `Resolution: ${this.webrtcVideoStats.inboundRTPVideoStream.track.frameWidth}x${this.webrtcVideoStats.inboundRTPVideoStream.track.frameHeight}`,
          },
          {
            name: `Datarate: ${d3.format(bandwidthFormat)(
              this.getDatarate(this.webrtcVideoStats.inboundRTPVideoStream.transport.selectedCandidatePair) * 8
            )} kbps`,
          },
          {
            name: `Packets Lost: ${d3.format(numberFormat)(this.webrtcVideoStats.inboundRTPVideoStream.packetsLost)}`,
          },
          {
            name: `Dropped Frames: ${d3.format(numberFormat)(
              this.webrtcVideoStats.inboundRTPVideoStream.track.framesDropped
            )}`,
          },
          {
            name: `Full Pictures Lost: ${d3.format(numberFormat)(
              this.webrtcVideoStats.inboundRTPVideoStream.pliCount
            )}`,
          },
          {
            name: `Ping: ${d3.format(delayFormat)(
              this.getPing(this.webrtcVideoStats.inboundRTPVideoStream.transport.selectedCandidatePair)
            )} ms`,
          },
          {
            name: `Jitter: ${d3.format(delayFormat)(
              this.getJitter(this.webrtcVideoStats.inboundRTPVideoStream.track)
            )} ms`,
          },
          {
            name: `Decoding FPS: ${d3.format(fpsFormat)(
              this.webrtcVideoStats.inboundRTPVideoStream.framesDecoded /
                this.webrtcVideoStats.inboundRTPVideoStream.totalDecodeTime
            )}`,
          },
          {
            name: `Incoming FPS: ${d3.format(fpsFormat)(
              this.webrtcVideoStats.inboundRTPVideoStream.framesDecoded /
                this.webrtcVideoStats.inboundRTPVideoStream.totalInterFrameDelay
            )} `,
          },
        ],
      });
    }

    if (this.webrtcAudioStats && this.webrtcAudioStats.inboundRTPAudioStream) {
      items.push({
        name: "Audio Stats",
        children: [
          {
            name: `Codec: ${this.webrtcAudioStats.inboundRTPAudioStream.codec.mimeType}`,
          },
          {
            name: `Datarate: ${d3.format(bandwidthFormat)(
              this.getDatarate(this.webrtcAudioStats.inboundRTPAudioStream.transport.selectedCandidatePair) * 8
            )} kbps`,
          },
          {
            name: `Packets Lost: ${d3.format(numberFormat)(this.webrtcAudioStats.inboundRTPAudioStream.packetsLost)}`,
          },
          {
            name: `Ping: ${d3.format(delayFormat)(
              this.getPing(this.webrtcAudioStats.inboundRTPAudioStream.transport.selectedCandidatePair)
            )} ms`,
          },
          {
            name: `Jitter: ${d3.format(delayFormat)(
              this.getJitter(this.webrtcAudioStats.inboundRTPAudioStream.track)
            )} ms`,
          },
        ],
      });
    }
    return items;
  }

  getPing(iceCandidatePair: IceCandidatePair): number {
    return iceCandidatePair.currentRoundTripTime * 1000;
  }

  getDatarate(iceCandidatePair: IceCandidatePair): number {
    return iceCandidatePair.bytesReceivedPerSeconds / 1024;
  }

  getJitter(track: RTCMediaStreamTrackStats): number {
    return (track.jitterBufferDelay / track.jitterBufferEmittedCount) * 1000;
  }
}
