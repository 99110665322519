import * as URLParamsManager from "./URLParamsManager";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Speedtest: any;

enum TestState {
  TEST_NOT_STARTED = -1,
  TEST_STARTING = 0,
  DOWNLOAD_TEST_IN_PROGRESS = 1,
  PING_JITTER_TEST_IN_PROGRESS = 2,
  UPLOAD_TEST_IN_PROGRESS = 3,
  TEST_FINISHED = 4,
  TEST_ABORTED = 5,
}

type TestStatus = "" | string | "Fail";

export interface SpeedTestResult {
  clientIp: string | "";
  dlProgress: number;
  /**
   * In Mbps
   */
  dlStatus: TestStatus;
  /**
   * In ms
   */
  jitterStatus: TestStatus;
  adjustedJitter: TestStatus;
  pingProgress: number;
  /**
   * In ms
   */
  pingStatus: TestStatus;
  adjustedPing: TestStatus;
  pingList: number[];
  testId: unknown | null;
  testState: TestState;
  ulProgress: number;
  /**
   * In Mbps
   */
  ulStatus: TestStatus;
}

export interface SpeedTestResultForStats {
  testId: unknown | null;
  clientIp: string | "";
  pingSuccess: boolean;
  pingResult: number;
  adjustedPing: number;
  pingList: number[];
  dlSuccess: boolean;
  dlResult: number;
  ulSuccess: boolean;
  ulResult: number;
  jitterSuccess: boolean;
  jitterResult: number;
  adjustedJitter: number;
  adjusted: boolean;
}

export default class ClientSpeedTest {
  public static readonly SKIP_SPEEDTEST: boolean = URLParamsManager.DEBUG || URLParamsManager.FORCE;

  public speedTestResult: Promise<SpeedTestResult>;
  public speedTestResultResolved = false;

  private readonly entryPointAdress: string;

  constructor(entryPointAdress: string) {
    this.entryPointAdress = entryPointAdress;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public resultForStats(result: SpeedTestResult): SpeedTestResultForStats {
    return {
      testId: result.testId,
      clientIp: result.clientIp,
      pingSuccess: result.pingStatus !== "" && result.pingStatus !== "Fail",
      pingResult: result.pingStatus === "Fail" ? -1 : Number(result.pingStatus),
      adjustedPing: result.adjustedPing === "Fail" ? -1 : Number(result.adjustedPing),
      pingList: result.pingList,
      dlSuccess: result.dlStatus !== "" && result.dlStatus !== "Fail",
      dlResult: result.dlStatus === "Fail" ? -1 : Number(result.dlStatus),
      ulSuccess: result.ulStatus !== "" && result.ulStatus !== "Fail",
      ulResult: result.ulStatus === "Fail" ? -1 : Number(result.ulStatus),
      jitterSuccess: result.jitterStatus !== "" && result.jitterStatus !== "Fail",
      jitterResult: result.jitterStatus === "Fail" ? -1 : Number(result.jitterStatus),
      adjustedJitter: result.adjustedJitter === "Fail" ? -1 : Number(result.adjustedJitter),
      adjusted: result.adjustedJitter !== result.jitterStatus,
    };
  }

  public LaunchSpeedTest(): void {
    if (this.speedTestResult && !this.speedTestResultResolved)
      return console.warn("(SpeedTest) Cannot start SpeedTest: A speedtest is already ongoing");

    const st = new Speedtest();
    st.setParameter("test_order", "P_D"); // We only want ping and download test with 1 seconds delay in between
    st.setParameter("count_ping", "10"); // How many pings to perform in the ping test
    st.setParameter("time_dl_max", 5); // 5 seconds max for the download test
    st.setParameter("xhr_ignoreErrors", 0); // We fail a test on error and don't restart it
    st.setParameter("garbagePhp_chunkSize", 30); // Each call to /garbage will generate about 30MB of data
    // st.setParameter("time_auto", false);
    st.setSelectedServer({
      dlURL: "garbage", // path to download test on this server
      getIpURL: "getIP", // path to getIP on this server (not used)
      name: "EntryPoint", // user friendly name for the server
      pingURL: "empty", // path to ping/jitter test on this server (empty.php or replacement)
      server: `${this.entryPointAdress}/speedtest/`, // URL to the server.
      ulURL: "empty", // path to upload test on this server (not used)
    });

    let timeoutID: number;
    const start = Date.now();
    this.speedTestResult = new Promise<SpeedTestResult>((res) => {
      st.onupdate = (result: SpeedTestResult) => {
        // when status is received, put the values in the appropriate fields
        if (result.testState === TestState.TEST_FINISHED) {
          if (result.dlStatus !== "Fail" && result.pingStatus !== "Fail") {
            console.log(`(SpeedTest) Speedtest done in ${(Date.now() - start) / 1000}s:`, result);
            res(result);
          } else {
            console.error(`(SpeedTest) Speedtest failed after ${(Date.now() - start) / 1000}s:`, result);
            res(undefined);
          }
        }
      };
      if (ClientSpeedTest.SKIP_SPEEDTEST) {
        console.debug("(SpeedTest) DEBUG is on: not waiting for end of SpeedTest.");
        res(undefined);
      } else {
        timeoutID = setTimeout(() => {
          console.warn("(SpeedTest) SpeedTest was taking too long... Continuing");
          res(undefined);
        }, 15000);
      }
    });
    this.speedTestResult.then(() => {
      this.speedTestResultResolved = true;
      clearTimeout(timeoutID);
    });
    st.start();
    console.log("(SpeedTest) Starting Speedtest...");
  }
}
