












































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

import * as Stats from "../old-client-stuff/Stat";

@Component
export default class TheRatingCard extends Vue {
  step = 1;
  sending = false;
  selectedIssues: number[] = [];
  rating = 0;
  issues: string[] = [
    i18n.t("play.rating.issues.list.long").toString(),
    i18n.t("play.rating.issues.list.pixelated").toString(),
    i18n.t("play.rating.issues.list.choppy").toString(),
    i18n.t("play.rating.issues.list.latency").toString(),
    i18n.t("play.rating.issues.list.controls").toString(),
    i18n.t("play.rating.issues.list.crash").toString(),
    i18n.t("play.rating.issues.list.freeze").toString(),
    i18n.t("play.rating.issues.list.nosave").toString(),
    i18n.t("play.rating.issues.list.notlike").toString(),
  ];
  suggestion = "";
  otherIssue = "";
  words: string[] = [
    i18n.t("play.rating.ratings.awful").toString(),
    i18n.t("play.rating.ratings.bad").toString(),
    i18n.t("play.rating.ratings.ok").toString(),
    i18n.t("play.rating.ratings.good").toString(),
    i18n.t("play.rating.ratings.excellent").toString(),
  ];

  @Prop(Boolean) readonly dialog: boolean;
  @Prop(String) readonly webrtcID: string;
  @Prop(String) readonly statID: string;

  @Watch("value")
  onValueChange(): void {
    this.step = 1;
  }

  get show(): boolean {
    return this.dialog;
  }

  set show(value: boolean) {
    this.$emit("close");
  }

  validateStep1(): void {
    if (this.rating <= 3) this.step = 2;
    else this.send();
  }

  validateStep2(): void {
    this.send();
  }

  get mailtoLink(): string {
    return `mailto:feedback@caregame.com?subject=Suggestion&body=${encodeURIComponent(this.suggestion)}`;
  }

  get allIssues(): string[] {
    const issues = this.selectedIssues.map((i) => this.issues[i]).concat();
    if (this.otherIssue) issues.push(this.otherIssue);
    return issues;
  }

  send(): void {
    this.sending = true;
    Stats.SentRatingStats({
      webrtc_session_id: this.webrtcID,
      game: this.$route.params.game,
      stat_id: this.statID,
      username: this.$store.direct.state.authentication.user?.username,
      rating: this.rating,
      issues: this.allIssues,
    });
    setTimeout(() => this.$emit("close"), 1000);
  }

  deactivated(): void {
    this.step = 1;
    this.sending = false;
    this.rating = 0;
    this.otherIssue = "";
    this.suggestion = "";
    this.selectedIssues = [];
  }
}
