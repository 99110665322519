import screenfull from "screenfull";
import { Screenfull as ScreenfullType } from "screenfull";

export const Screenfull: ScreenfullType = screenfull as ScreenfullType;

// From https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
// tslint:disable
export function formatBytes(nbr: number, fractionsDigits?: number): string {
  if (0 == nbr) return "0 Bytes";
  const c = 1024,
    d = fractionsDigits || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(nbr) / Math.log(c));
  return `${parseFloat((nbr / Math.pow(c, f)).toFixed(d))} ${e[f]}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setTimeoutPromise(callback: (...args: any[]) => void, ms: number): Promise<unknown> {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, callback), ms);
  });
}

export const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}
