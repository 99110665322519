





























































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import AddToHomeTuto from "@/components/AddToHomeTuto.vue";
import PlayHelp from "@/components/PlayHelp.vue";
import PlayHelpNetwork from "@/components/PlayHelpNetwork.vue";
import PlayLoader from "@/components/PlayLoader.vue";
import PlayOverlayMenu from "@/components/PlayOverlayMenu.vue";
import PlayOverlay from "@/components/PlayOverlay.vue";
import PlayTimer from "@/components/PlayTimer.vue";
import TheRatingCard from "@/components/TheRatingCard.vue";
import TheWebRTCStats from "@/components/TheWebRTCStats.vue";
import PleaseLogin from "@/components/PleaseLogin.vue";

import App from "@/old-client-stuff/App";
import * as MobileManager from "@/old-client-stuff/MobileManager";
import * as StateManager from "@/old-client-stuff/StateManager";
import * as URLParamsManager from "@/old-client-stuff/URLParamsManager";

import { Game } from "@/store/modules/games";
import { ParsedWebRTCStats } from "@/WebRTC/WebRTCStatsParser";
import { UserPermission } from "@/store/modules/authentication";

@Component({
  components: {
    AddToHomeTuto,
    PlayHelp,
    PlayHelpNetwork,
    PlayLoader,
    PlayOverlay,
    PlayOverlayMenu,
    PlayTimer,
    TheRatingCard,
    TheWebRTCStats,
    PleaseLogin,
  },
})
export default class PlayView extends Vue {
  muted = true;
  helpDialog = false;
  helpNetworkDialog = false;
  tutoDialog = false;
  pleaseLoginDialog = false;
  fabDirection = "bottom";
  showRatingPopup = false;
  showStats = false;
  webrtcID = "";
  statID = "";
  progress = 0;
  networkStrengh = 0;
  speedtestResultPing = 0;
  speedtestResultDl = 0;
  browserType = "";
  config = null;

  myApp: App | null = null;

  android = MobileManager.getMobileOS() === MobileManager.MobileOS.ANDROID;
  currentState: StateManager.State = StateManager.State.LOADER;
  debug = URLParamsManager.DEBUG;
  facebookApp = MobileManager.isFacebookApp();
  orientation = MobileManager.getMobileOrientation();
  webrtcVideoStats: ParsedWebRTCStats | null = null;
  webrtcAudioStats: ParsedWebRTCStats | null = null;
  endTrialDate: Date = new Date();
  showTimer = false;
  pleaseLoginTitle = "";
  endByTrial: boolean;

  addClassToBody(): void {
    var body = document.body;
    body.classList.add("no-handle-notch");
  }
  deleteClassFromBody(): void {
    var body = document.body;
    body.classList.remove("no-handle-notch");
  }
  showTuto(): void {
    if (this.shouldShowTuto) this.tutoDialog = true;
    else this.start();
  }

  showPleaseLoginBefore(): void {
    if (
      this.$store.direct.state.authentication.user?.temporaryAccount &&
      this.game &&
      this.game?.trialTimeout &&
      this.game?.trialTimeout > 0
    ) {
      const minutes = Math.floor(this.game.trialTimeout / 60)
        .toString()
        .padStart(2, "0");
      const seconds = (this.game.trialTimeout % 60).toString().padEnd(2, "0");
      const timer = `${minutes}:${seconds}`;
      this.pleaseLoginTitle = this.$t("pleaselogin.title.beforeGame.trial", { timer: timer }).toString();
      this.pleaseLoginDialog = true;
    } else this.showTuto();
  }

  showPleaseLoginAfter(): void {
    if (this.$store.direct.state.authentication.user?.temporaryAccount) {
      this.pleaseLoginDialog = true;
      if (this.endByTrial) this.pleaseLoginTitle = this.$t("pleaselogin.title.afterGame.trial").toString();
      else this.pleaseLoginTitle = this.$t("pleaselogin.title.afterGame.quit").toString();
    } else this.$router.back();
  }

  tutoClosed(): void {
    this.tutoDialog = false;
    this.start();
  }

  pleaseLoginClosed(): void {
    this.pleaseLoginDialog = false;
    if (this.progress === 0) this.showTuto();
    else this.$router.back();
  }

  closeRating(): void {
    this.showRatingPopup = false;
    this.showPleaseLoginAfter();
  }

  fullscreen(): void {
    console.debug("(Video) Requesting Fullscreen");
    const elem = document.documentElement;
    if (elem.requestFullscreen) elem.requestFullscreen({ navigationUI: "hide" });
    else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
    else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen({ navigationUI: "hide" });
    else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
  }

  get favorite(): boolean {
    if (!this.game) return false;
    return this.$store.direct.state.games.favoriteGames.includes(this.game._id);
  }

  async toggleFavorite(): Promise<void> {
    if (!this.game) return;
    if (this.$store.direct.state.authentication.user?.temporaryAccount) {
      this.$store.direct.commit.SHOW_ALERT({
        title: this.$t("play.overlay.favorite.isGuest.title").toString(),
        text: this.$t("play.overlay.favorite.isGuest.text").toString(),
      });
      return;
    }
    if (this.favorite) await this.$store.direct.dispatch.games.removeFromFavorite(this.game);
    else await this.$store.direct.dispatch.games.addToFavorite(this.game);
  }

  get game(): Game | undefined {
    return this.$store.direct.getters.games.getGame(this.$route.params.game);
  }

  showPlsRotate(): void {
    if (this.game?.orientation === "PORTRAIT" && (this.android || !this.facebookApp)) {
      document.getElementById("playOverlay")?.classList.add("portrait");
      document.getElementById("playOverlay")?.classList.remove("landscape");
    } else {
      document.getElementById("playOverlay")?.classList.add("landscape");
      document.getElementById("playOverlay")?.classList.remove("portait");
    }
  }
  get shouldShowTuto(): boolean {
    return (
      !this.$store.direct.getters.authentication.hasPermission(UserPermission.SKIP_ADD2HOME) &&
      !this.$store.direct.getters.mobile.isWebApp &&
      (this.$store.direct.getters.mobile.isiOS || !this.$store.direct.state.mobile.noMoreTuto)
    );
  }

  start(): void {
    if (this.myApp) return;
    this.myApp = new App(this);
    this.myApp.Init();
    this.fullscreen();
    this.showPlsRotate();
  }

  restart(): void {
    this.myApp?.Restart();
  }

  reset(): void {
    this.myApp?.Close();
    this.statID = this.myApp?.statId || "";
    this.webrtcID = this.myApp?.webrtcController.webRTCId || "";
    this.myApp = null;
    this.deleteClassFromBody();
  }

  back(endByTrial = false): void {
    this.endByTrial = endByTrial;
    this.showRatingPopup = true;
    this.currentState = StateManager.State.NONE;
    this.reset();
  }

  init(): void {
    try {
      this.currentState = StateManager.State.LOADER;
      this.progress = 0;
      this.tutoDialog = false;
      this.pleaseLoginDialog = false;
      this.showRatingPopup = false;
      this.showTimer = false;
      this.addClassToBody();

      this.showPleaseLoginBefore();
    } catch (err) {
      console.error(err);
    }
  }

  activated(): void {
    this.init();
  }

  deactivated(): void {
    this.reset();
  }

  mounted(): void {
    this.init();
  }

  beforeDestroy(): void {
    this.reset();
  }
}
