
































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Game } from "@/store/modules/games";

@Component
export default class PlayLoader extends Vue {
  @Prop({
    type: Number,
    validator: (v) => v >= 0 && v <= 100,
  })
  readonly progress: number;

  get game(): Game | undefined {
    return this.$store.direct.getters.games.getGame(this.$route.params.game);
  }
}
