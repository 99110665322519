


























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  filters: {
    addPaddingZero(value: number): string {
      return value.toString().padStart(2, "0");
    },
  },
})
export default class PlayTimer extends Vue {
  @Prop(Date) endDate: Date;

  @Watch("endDate")
  endDateChanged(): void {
    this.reset();
    this.init();
  }

  remainingTime = 0;
  intervalHandle: number | undefined;
  intervalTimeout = 500;

  get minutes(): number {
    return Math.floor(this.remainingTime / 60);
  }

  get seconds(): number {
    return Math.floor(this.remainingTime % 60);
  }

  /**
   * Get the remaining time, in seconds.
   */
  calculateRemainingTime(): void {
    this.remainingTime = Math.max((this.endDate.getTime() - Date.now()) / 1000, 0);
  }

  init(): void {
    if (!this.intervalHandle) {
      this.calculateRemainingTime();
      this.intervalHandle = setInterval(this.calculateRemainingTime, this.intervalTimeout);
    }
  }

  reset(): void {
    clearInterval(this.intervalHandle);
    this.intervalHandle = undefined;
  }
}
