





























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PlayHelpNetwork extends Vue {
  @Prop(Boolean) dialog: boolean;
  @Prop(Number) networkStrengh: number;

  get show(): boolean {
    return this.dialog;
  }

  set show(value: boolean) {
    this.$emit("close");
  }

  get showWarning(): boolean {
    return this.networkStrengh <= 0.33;
  }
}
