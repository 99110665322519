import { EventEmitter } from "events";

import * as WebRTCStatsParser from "./WebRTCStatsParser";
import * as FirefoxRTCStatsTypes from "./FirefoxRTCStatsTypes";
import { RTCCodecStats, RTCMediaStreamTrackAudioStats, RTCMediaStreamTrackVideoStats } from "./DefaultRTCStatsTypes";
import { assertNever } from "@/old-client-stuff/Utils";

export interface FirefoxRawData {
  dataChannel: FirefoxRTCStatsTypes.RTCDataChannelStats;
  iceCandidatePairs: FirefoxRTCStatsTypes.RTCIceCandidatePairStats[];
  iceCandidates: {
    locals: FirefoxRTCStatsTypes.RTCIceCandidateLocalStats[];
    remotes: FirefoxRTCStatsTypes.RTCIceCandidateRemoteStats[];
  };
  inboundStreams: FirefoxRTCStatsTypes.RTCInboundRTPStreamStats[];
  remoteOutboundStreams: FirefoxRTCStatsTypes.RTCRemoteOutboundRTPStats[];
}

export class FirefoxWebRTCStatsParser extends EventEmitter implements WebRTCStatsParser.IStatsParser {
  private readonly history: WebRTCStatsParser.ParsedWebRTCStats[];
  private readonly ping: number;
  private readonly historyLimit = 100;

  constructor(ping: number) {
    super();
    this.ping = ping;
    this.history = [];
  }

  private parseRawData(rawStats: FirefoxRTCStatsTypes.RTCStats[]): FirefoxRawData {
    const iceCandidatePairs: FirefoxRTCStatsTypes.RTCIceCandidatePairStats[] = [];
    const inboundStreams: FirefoxRTCStatsTypes.RTCInboundRTPStreamStats[] = [];
    const remoteOutboundStreams: FirefoxRTCStatsTypes.RTCRemoteOutboundRTPStats[] = [];
    const localIceCandidates: FirefoxRTCStatsTypes.RTCIceCandidateLocalStats[] = [];
    const remoteIceCandidates: FirefoxRTCStatsTypes.RTCIceCandidateRemoteStats[] = [];
    let dataChannel!: FirefoxRTCStatsTypes.RTCDataChannelStats;
    rawStats.forEach((s) => {
      switch (s.type) {
        case FirefoxRTCStatsTypes.RTCStatsType.CANDIDATE_PAIR:
          iceCandidatePairs?.push(s as FirefoxRTCStatsTypes.RTCIceCandidatePairStats);
          break;
        case FirefoxRTCStatsTypes.RTCStatsType.DATA_CHANNEL:
          dataChannel = s as FirefoxRTCStatsTypes.RTCDataChannelStats;
          break;
        case FirefoxRTCStatsTypes.RTCStatsType.INBOUND_RTP:
          inboundStreams?.push(s as FirefoxRTCStatsTypes.RTCInboundRTPStreamStats);
          break;
        case FirefoxRTCStatsTypes.RTCStatsType.LOCAL_CANDIDATE:
          localIceCandidates?.push(s as FirefoxRTCStatsTypes.RTCIceCandidateLocalStats);
          break;
        case FirefoxRTCStatsTypes.RTCStatsType.REMOTE_CANDIDATE:
          remoteIceCandidates?.push(s as FirefoxRTCStatsTypes.RTCIceCandidateRemoteStats);
          break;
        case FirefoxRTCStatsTypes.RTCStatsType.REMOTE_OUTBOUND_RTP:
          remoteOutboundStreams?.push(s as FirefoxRTCStatsTypes.RTCRemoteOutboundRTPStats);
          break;
        default:
          assertNever(s.type);
      }
    });
    return {
      dataChannel,
      iceCandidatePairs,
      iceCandidates: {
        locals: localIceCandidates,
        remotes: remoteIceCandidates,
      },
      inboundStreams,
      remoteOutboundStreams,
    };
  }

  /**
   * Create a fake codec stat element with data copied from existing stats on Kibana
   */
  private makeDummyCodec(rawData: FirefoxRawData, kind: "audio" | "video"): RTCCodecStats {
    if (kind === "video")
      return {
        id: "dummyVideoID",
        timestamp: rawData.inboundStreams[0].timestamp,
        type: "codec",
        payloadType: 96,
        mimeType: "unknown",
        clockRate: 90000,
        sdpFmtpLine: "level-asymmetry-allowed=1;packetization-mode=1;profile-level-id=42e015",
      };
    else
      return {
        id: "dummyAudioID",
        timestamp: rawData.inboundStreams[0].timestamp,
        type: "codec",
        payloadType: 110,
        mimeType: "audio/OPUS",
        clockRate: 48000,
        sdpFmtpLine: "minptime=10;useinbandfec=1",
      };
  }

  /**
   * Create a Track stat element with data from the rawData
   */
  private createTrack(
    rawData: FirefoxRawData,
    kind: "audio" | "video"
  ): RTCMediaStreamTrackVideoStats | RTCMediaStreamTrackAudioStats {
    if (kind === "video")
      return {
        id: "DummyTrackID",
        timestamp: rawData.inboundStreams[0].timestamp,
        type: "track",
        trackIdentifier: "webrtctransceiver0",
        remoteSource: true,
        ended: false,
        detached: false,
        kind: "video",
        jitterBufferDelay: rawData.inboundStreams[0].jitter,
        jitterBufferEmittedCount: 1,
        frameWidth: 0,
        frameHeight: 0,
        framesReceived: 0,
        framesDecoded: 0,
        framesDropped: 0,
      } as RTCMediaStreamTrackVideoStats;
    else
      return {
        id: "DummyTrackID",
        timestamp: rawData.inboundStreams[0].timestamp,
        type: "track",
        trackIdentifier: "webrtctransceiver1",
        remoteSource: true,
        ended: false,
        detached: false,
        kind: "audio",
        jitterBufferDelay: rawData.inboundStreams[0].jitter,
        jitterBufferEmittedCount: 1,
        audioLevel: 0,
        totalAudioEnergy: 0,
        totalSamplesReceived: 0,
        totalSamplesDuration: 0,
        concealedSamples: 0,
        silentConcealedSamples: 0,
        concealmentEvents: 0,
        insertedSamplesForDeceleration: 0,
        removedSamplesForAcceleration: 0,
      } as RTCMediaStreamTrackAudioStats;
  }

  private createTransport(rawData: FirefoxRawData, kind: "audio" | "video"): WebRTCStatsParser.Transport {
    const candidatePair = rawData.iceCandidatePairs.find((pair) => pair.selected);
    let previousCandidatePair;

    if (kind === "audio" && this.history[0].inboundRTPAudioStream) {
      previousCandidatePair = this.history[0].inboundRTPAudioStream.transport.selectedCandidatePair;
    } else if (kind === "video" && this.history[0].inboundRTPVideoStream) {
      previousCandidatePair = this.history[0].inboundRTPVideoStream.transport.selectedCandidatePair;
    }
    const localCandidate = candidatePair
      ? rawData.iceCandidates.locals.find((candidate) => candidate.id === candidatePair.localCandidateId)
      : undefined;
    const remoteCandidate = candidatePair
      ? rawData.iceCandidates.remotes.find((candidate) => candidate.id === candidatePair.remoteCandidateId)
      : undefined;
    const transport: WebRTCStatsParser.Transport = {
      id: "dummyTransportID",
      timestamp: rawData.inboundStreams[0].timestamp,
      bytesReceived: 0,
      bytesSent: 0,
      dtlsCipher: "dummyCiper",
      dtlsState: "dummyState",
      selectedCandidatePairChanges: 0,
      srtpCipher: "dummyCiper",
      tlsVersion: "dummyVersion",
      type: "transport",
      localCertificate: {
        id: "dummyCert",
        timestamp: rawData.inboundStreams[0].timestamp,
        type: "certificate",
        base64Certificate: "dummy64Cert",
        fingerprint: "dummyFingerPrint",
        fingerprintAlgorithm: "dummyAlgo",
      },
      remoteCertificate: {
        id: "dummyCert",
        timestamp: rawData.inboundStreams[0].timestamp,
        type: "certificate",
        base64Certificate: "dummy64Cert",
        fingerprint: "dummyFingerPrint",
        fingerprintAlgorithm: "dummyAlgo",
      },
      selectedCandidatePair: {
        id: "dummyPair",
        timestamp: rawData.inboundStreams[0].timestamp,
        availableIncomingBitrate: (rawData.inboundStreams[0] as FirefoxRTCStatsTypes.RTCInboundRTPVideoStreamStats)
          .bitrateMean,
        availableOutgoingBitrate: 0,
        bytesReceived: 0,
        bytesSent: 0,
        consentRequestsSent: 0,
        currentRoundTripTime: this.ping / 1000,
        nominated: true,
        priority: 0,
        requestsReceived: 0,
        requestsSent: 0,
        responsesReceived: 0,
        responsesSent: 0,
        state: "failed",
        totalRoundTripTime: 0,
        type: FirefoxRTCStatsTypes.RTCStatsType.CANDIDATE_PAIR,
        writable: true,
        bytesReceivedPerSeconds: 0,
        localCandidate: {
          id: "dummyLocalCandidate",
          timestamp: rawData.inboundStreams[0].timestamp,
          candidateType: "host",
          deleted: false,
          ip: "dummyIP",
          isRemote: false,
          port: 0,
          priority: 0,
          protocol: "udp",
          networkType: "unknown",
          type: FirefoxRTCStatsTypes.RTCStatsType.LOCAL_CANDIDATE,
          transportId: "dummyTransportID",
        },
        remoteCandidate: {
          id: "dummyRemoteCandidate",
          timestamp: rawData.inboundStreams[0].timestamp,
          candidateType: "host",
          deleted: false,
          ip: "dummyIP",
          isRemote: true,
          port: 0,
          priority: 0,
          protocol: "udp",
          type: FirefoxRTCStatsTypes.RTCStatsType.REMOTE_CANDIDATE,
          transportId: "dummyTransportID",
        },
      },
    };
    if (candidatePair) {
      if (previousCandidatePair) {
        transport.selectedCandidatePair.bytesReceivedPerSeconds =
          (candidatePair.bytesReceived - previousCandidatePair.bytesReceived) /
          ((candidatePair.timestamp - previousCandidatePair.timestamp) / 1000);
      }
      transport.bytesReceived = candidatePair.bytesReceived;
      transport.bytesSent = candidatePair.bytesSent;
      transport.selectedCandidatePair.id = candidatePair.id;
      transport.selectedCandidatePair.timestamp = candidatePair.timestamp;
      transport.selectedCandidatePair.bytesReceived = candidatePair.bytesReceived;
      transport.selectedCandidatePair.bytesSent = candidatePair.bytesSent;
      transport.selectedCandidatePair.priority = candidatePair.priority;
      transport.selectedCandidatePair.state = candidatePair.state;
      transport.selectedCandidatePair.writable = candidatePair.writable;
    }
    if (localCandidate) {
      transport.selectedCandidatePair.localCandidate.id = localCandidate.id;
      transport.selectedCandidatePair.localCandidate.timestamp = localCandidate.timestamp;
      transport.selectedCandidatePair.localCandidate.candidateType = localCandidate.candidateType;
      transport.selectedCandidatePair.localCandidate.ip = localCandidate.address;
      transport.selectedCandidatePair.localCandidate.port = localCandidate.port;
      transport.selectedCandidatePair.localCandidate.priority = localCandidate.priority;
      transport.selectedCandidatePair.localCandidate.protocol = localCandidate.protocol;
    }
    if (remoteCandidate) {
      transport.selectedCandidatePair.remoteCandidate.id = remoteCandidate.id;
      transport.selectedCandidatePair.remoteCandidate.timestamp = remoteCandidate.timestamp;
      transport.selectedCandidatePair.remoteCandidate.candidateType = remoteCandidate.candidateType;
      transport.selectedCandidatePair.remoteCandidate.ip = remoteCandidate.address;
      transport.selectedCandidatePair.remoteCandidate.port = remoteCandidate.port;
      transport.selectedCandidatePair.remoteCandidate.priority = remoteCandidate.priority;
      transport.selectedCandidatePair.remoteCandidate.protocol = remoteCandidate.protocol;
    }

    return transport;
  }

  private createInboundStream(
    rawData: FirefoxRawData,
    kind: "audio" | "video",
    codec: RTCCodecStats,
    track: RTCMediaStreamTrackVideoStats | RTCMediaStreamTrackAudioStats,
    transport: WebRTCStatsParser.Transport
  ): WebRTCStatsParser.InboundRTPVideoStream | WebRTCStatsParser.InboundRTPAudioStream {
    if (kind === "video") {
      const inboundRTPVideoStream = rawData.inboundStreams[0] as FirefoxRTCStatsTypes.RTCInboundRTPVideoStreamStats;
      return {
        id: inboundRTPVideoStream.id,
        timestamp: inboundRTPVideoStream.timestamp,
        type: FirefoxRTCStatsTypes.RTCStatsType.INBOUND_RTP,
        bytesReceived: inboundRTPVideoStream.bytesReceived,
        estimatedPlayoutTimestamp: 0,
        headerBytesReceived: 0,
        isRemote: true,
        kind: "video",
        lastPacketReceivedTimestamp: 0,
        mediaType: "video",
        packetsLost: inboundRTPVideoStream.packetsLost,
        packetsReceived: inboundRTPVideoStream.packetsReceived,
        ssrc: inboundRTPVideoStream.ssrc,
        decoderImplementation: "",
        firCount: inboundRTPVideoStream.firCount,
        framesDecoded: inboundRTPVideoStream.framerateMean,
        keyFramesDecoded: 0,
        nackCount: inboundRTPVideoStream.nackCount,
        pliCount: inboundRTPVideoStream.pliCount,
        totalDecodeTime: 1,
        totalInterFrameDelay: 1,
        totalSquaredInterFrameDelay: 0,
        codec,
        track: track as RTCMediaStreamTrackVideoStats,
        transport,
      };
    } else {
      const inboundStream = rawData.inboundStreams[0] as FirefoxRTCStatsTypes.RTCInboundRTPAudioStreamStats;
      return {
        id: inboundStream.id,
        timestamp: inboundStream.timestamp,
        fecPacketsDiscarded: inboundStream.fecPacketsDiscarded,
        fecPacketsReceived: inboundStream.fecPacketsReceived,
        jitter: inboundStream.jitter,
        kind: "audio",
        mediaType: "audio",
        bytesReceived: inboundStream.bytesReceived,
        estimatedPlayoutTimestamp: 0,
        headerBytesReceived: 0,
        isRemote: true,
        lastPacketReceivedTimestamp: 0,
        packetsLost: inboundStream.packetsLost,
        packetsReceived: inboundStream.packetsReceived,
        ssrc: inboundStream.ssrc,
        type: FirefoxRTCStatsTypes.RTCStatsType.INBOUND_RTP,
        codec,
        track: track as RTCMediaStreamTrackAudioStats,
        transport,
      };
    }
  }

  /**
   * This function transpose raw data from firefox to the format used by the default stat parser.
   */
  private parseInboundStream(
    rawData: FirefoxRawData,
    kind: "audio" | "video"
  ): WebRTCStatsParser.InboundRTPAudioStream | WebRTCStatsParser.InboundRTPVideoStream {
    const codec = this.makeDummyCodec(rawData, kind);
    const track = this.createTrack(rawData, kind);
    const transport = this.createTransport(rawData, kind);
    return this.createInboundStream(rawData, kind, codec, track, transport);
  }

  public parse(rawStats: FirefoxRTCStatsTypes.RTCStats[]): void {
    if (rawStats.length === 0) return;

    const rawData = this.parseRawData(rawStats);

    const dataChannel = {
      ...rawData.dataChannel,
      datachannelid: rawData.dataChannel?.dataChannelIdentifier,
    };
    const parsedData: WebRTCStatsParser.ParsedWebRTCStats = {
      timestamp: rawStats[0].timestamp,
      date: new Date(rawStats[0].timestamp),
      dataChannel,
      rawData,
    };
    try {
      parsedData.inboundRTPVideoStream = this.parseInboundStream(
        rawData,
        "video"
      ) as WebRTCStatsParser.InboundRTPVideoStream;
    } catch (e) {
      console.debug(`Could not create InboundStream:`, e.stack);
      parsedData.inboundRTPVideoStream = undefined;
    }
    try {
      parsedData.inboundRTPAudioStream = this.parseInboundStream(
        rawData,
        "audio"
      ) as WebRTCStatsParser.InboundRTPAudioStream;
    } catch (e) {
      console.debug(`Could not create InboundStream:`, e.stack);
      parsedData.inboundRTPAudioStream = undefined;
    }
    this.history.unshift(parsedData);
    if (this.history.length > this.historyLimit) this.history.pop();
    this.emit("stats", this.parsedStats);
  }

  public get parsedStats(): WebRTCStatsParser.ParsedWebRTCStats {
    return this.history[0];
  }
}
