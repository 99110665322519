import { EventEmitter } from "events";

import * as WebRTCStatsParser from "./WebRTCStatsParser";
import * as DefaultRTCStatsTypes from "./DefaultRTCStatsTypes";
import { assertNever } from "@/old-client-stuff/Utils";

export interface DefaultRawData {
  certificates: DefaultRTCStatsTypes.RTCCertificateStats[];
  codecs: DefaultRTCStatsTypes.RTCCodecStats[];
  dataChannel: DefaultRTCStatsTypes.RTCDataChannelStats;
  iceCandidatePairs: DefaultRTCStatsTypes.RTCIceCandidatePairStats[];
  iceCandidates: {
    locals: DefaultRTCStatsTypes.RTCIceCandidateLocalStats[];
    remotes: DefaultRTCStatsTypes.RTCIceCandidateRemoteStats[];
  };
  inboundStreams: DefaultRTCStatsTypes.RTCInboundRTPStreamStats[];
  mediaStreamTracks: DefaultRTCStatsTypes.RTCMediaStreamTrackStats[];
  peerConnection: DefaultRTCStatsTypes.RTCPeerConnectionStats;
  stream: DefaultRTCStatsTypes.RTCMediaStreamStats;
  transport: DefaultRTCStatsTypes.RTCTransportStats;
}

export class DefaultWebRTCStatsParser extends EventEmitter implements WebRTCStatsParser.IStatsParser {
  private readonly historyLimit = 100;
  private readonly history: WebRTCStatsParser.ParsedWebRTCStats[];

  constructor() {
    super();
    this.history = [];
  }

  private parseRawData(rawStats: DefaultRTCStatsTypes.RTCStats[]): DefaultRawData {
    const iceCandidatePairs: DefaultRTCStatsTypes.RTCIceCandidatePairStats[] = [];
    const certificates: DefaultRTCStatsTypes.RTCCertificateStats[] = [];
    const codecs: DefaultRTCStatsTypes.RTCCodecStats[] = [];
    let dataChannel!: DefaultRTCStatsTypes.RTCDataChannelStats;
    const inboundStreams: DefaultRTCStatsTypes.RTCInboundRTPStreamStats[] = [];
    let peerConnection!: DefaultRTCStatsTypes.RTCPeerConnectionStats;
    const localIceCandidates: DefaultRTCStatsTypes.RTCIceCandidateLocalStats[] = [];
    const remoteIceCandidates: DefaultRTCStatsTypes.RTCIceCandidateRemoteStats[] = [];
    let stream!: DefaultRTCStatsTypes.RTCMediaStreamStats;
    const mediaStreamTracks: DefaultRTCStatsTypes.RTCMediaStreamTrackStats[] = [];
    let transport!: DefaultRTCStatsTypes.RTCTransportStats;
    rawStats.forEach((s) => {
      switch (s.type) {
        case "candidate-pair":
          iceCandidatePairs?.push(s as DefaultRTCStatsTypes.RTCIceCandidatePairStats);
          break;
        case "certificate":
          certificates?.push(s as DefaultRTCStatsTypes.RTCCertificateStats);
          break;
        case "codec":
          codecs?.push(s as DefaultRTCStatsTypes.RTCCodecStats);
          break;
        case "data-channel":
          dataChannel = s as DefaultRTCStatsTypes.RTCDataChannelStats;
          break;
        case "inbound-rtp":
          inboundStreams?.push(s as DefaultRTCStatsTypes.RTCInboundRTPStreamStats);
          break;
        case "peer-connection":
          peerConnection = s as DefaultRTCStatsTypes.RTCPeerConnectionStats;
          break;
        case "local-candidate":
          localIceCandidates?.push(s as DefaultRTCStatsTypes.RTCIceCandidateLocalStats);
          break;
        case "remote-candidate":
          remoteIceCandidates?.push(s as DefaultRTCStatsTypes.RTCIceCandidateRemoteStats);
          break;
        case "stream":
          stream = s as DefaultRTCStatsTypes.RTCMediaStreamStats;
          break;
        case "track":
          mediaStreamTracks?.push(s as DefaultRTCStatsTypes.RTCMediaStreamTrackStats);
          break;
        case "transport":
          transport = s as DefaultRTCStatsTypes.RTCTransportStats;
          break;
        case "remote-outbound-rtp":
          break;
        default:
          assertNever(s.type);
      }
    });
    return {
      certificates,
      codecs,
      dataChannel,
      iceCandidatePairs,
      iceCandidates: {
        locals: localIceCandidates,
        remotes: remoteIceCandidates,
      },
      inboundStreams,
      mediaStreamTracks,
      peerConnection,
      stream,
      transport,
    };
  }

  private FindByAttribute(
    stats: DefaultRTCStatsTypes.RTCStats[],
    attribute: string,
    value: unknown
  ): DefaultRTCStatsTypes.RTCStats | undefined {
    for (const s of stats) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((s as any)[attribute] === value) return s;
    }
    return undefined;
  }

  private parseInboundStream(
    rawData: DefaultRawData,
    kind: "audio" | "video"
  ): WebRTCStatsParser.InboundRTPAudioStream | WebRTCStatsParser.InboundRTPVideoStream {
    const inboundStreamData = this.FindByAttribute(rawData.inboundStreams, "mediaType", kind) as
      | DefaultRTCStatsTypes.RTCInboundRTPAudioStreamStats
      | DefaultRTCStatsTypes.RTCInboundRTPVideoStreamStats;

    const transportData = this.FindByAttribute(
      [rawData.transport],
      "id",
      inboundStreamData.transportId
    ) as DefaultRTCStatsTypes.RTCTransportStats;
    const newLocal = this.FindByAttribute(
      rawData.iceCandidatePairs,
      "id",
      transportData.selectedCandidatePairId
    ) as DefaultRTCStatsTypes.RTCIceCandidatePairStats;

    let previousCandidatePair;
    if (kind === "audio" && this.history[0].inboundRTPAudioStream) {
      previousCandidatePair = this.history[0].inboundRTPAudioStream.transport.selectedCandidatePair;
    } else if (kind === "video" && this.history[0].inboundRTPVideoStream) {
      previousCandidatePair = this.history[0].inboundRTPVideoStream.transport.selectedCandidatePair;
    }

    let bytesReceivedPerSeconds = NaN;
    if (previousCandidatePair) {
      bytesReceivedPerSeconds =
        (newLocal.bytesReceived - previousCandidatePair.bytesReceived) /
        ((newLocal.timestamp - previousCandidatePair.timestamp) / 1000);
    }

    const transport: WebRTCStatsParser.Transport = {
      ...transportData,
      localCertificate: this.FindByAttribute(
        rawData.certificates,
        "id",
        transportData.localCertificateId
      ) as DefaultRTCStatsTypes.RTCCertificateStats,
      remoteCertificate: this.FindByAttribute(
        rawData.certificates,
        "id",
        transportData.remoteCertificateId
      ) as DefaultRTCStatsTypes.RTCCertificateStats,
      selectedCandidatePair: {
        ...newLocal,
        localCandidate: this.FindByAttribute(
          rawData.iceCandidates.locals,
          "id",
          newLocal.localCandidateId
        ) as DefaultRTCStatsTypes.RTCIceCandidateLocalStats,
        remoteCandidate: this.FindByAttribute(
          rawData.iceCandidates.remotes,
          "id",
          newLocal.remoteCandidateId
        ) as DefaultRTCStatsTypes.RTCIceCandidateRemoteStats,
        bytesReceivedPerSeconds: bytesReceivedPerSeconds,
      },
    };
    return {
      ...inboundStreamData,
      codec: this.FindByAttribute(
        rawData.codecs,
        "id",
        inboundStreamData.codecId
      ) as DefaultRTCStatsTypes.RTCCodecStats,
      track: this.FindByAttribute(
        rawData.mediaStreamTracks,
        "id",
        inboundStreamData.trackId
      ) as DefaultRTCStatsTypes.RTCMediaStreamTrackAudioStats & DefaultRTCStatsTypes.RTCMediaStreamTrackVideoStats,
      transport: transport,
    };
  }

  public parse(rawStats: DefaultRTCStatsTypes.RTCStats[]): void {
    if (rawStats.length === 0) return;

    const rawData = this.parseRawData(rawStats);

    const parsedData: WebRTCStatsParser.ParsedWebRTCStats = {
      timestamp: rawStats[0].timestamp,
      date: new Date(rawStats[0].timestamp),
      dataChannel: rawData.dataChannel,
      rawData,
    };
    try {
      parsedData.inboundRTPVideoStream = this.parseInboundStream(
        rawData,
        "video"
      ) as WebRTCStatsParser.InboundRTPVideoStream;
    } catch (_) {
      parsedData.inboundRTPVideoStream = undefined;
    }
    try {
      parsedData.inboundRTPAudioStream = this.parseInboundStream(
        rawData,
        "audio"
      ) as WebRTCStatsParser.InboundRTPAudioStream;
    } catch (_) {
      parsedData.inboundRTPAudioStream = undefined;
    }
    this.history.unshift(parsedData);
    if (this.history.length > this.historyLimit) this.history.pop();
    this.emit("stats", this.parsedStats);
  }

  public get parsedStats(): WebRTCStatsParser.ParsedWebRTCStats {
    return this.history[0];
  }
}
