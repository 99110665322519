




























































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { UAParser } from "ua-parser-js";

import * as Utils from "@/old-client-stuff/Utils";
import { UserPermission } from "@/store/modules/authentication";

const parser = new UAParser();

@Component({
  directives: {
    draggable: {
      bind: function (el) {
        let startX: number, startY: number, initialMouseX: number, initialMouseY: number;

        function Clamp(n: number, min: number, max: number): number {
          return Math.min(Math.max(min, n), max);
        }

        function SetHeight(): void {
          const buttonList = document.querySelector(".v-speed-dial__list") as HTMLElement;
          buttonList.style.height = `${window.innerHeight - el.offsetTop + 25}px`;
        }

        function move(e: MouseEvent | TouchEvent): boolean {
          const dx = e instanceof MouseEvent ? e.clientX - initialMouseX : e.touches[0].clientX - initialMouseX;
          const dy = e instanceof MouseEvent ? e.clientY - initialMouseY : e.touches[0].clientY - initialMouseY;
          let newX = startX + dx;
          let newY = startY + dy;

          el.style.left = Clamp(newX, 0, window.innerWidth - el.clientWidth) + "px";
          el.style.top = Clamp(newY, 0, window.innerHeight - el.clientHeight) + "px";
          SetHeight();
          e.preventDefault();
          return false;
        }

        function end(): void {
          document.removeEventListener("mousemove", move);
          document.removeEventListener("mouseup", end);
          document.removeEventListener("touchmove", move);
          document.removeEventListener("touchend", end);
        }

        function start(e: MouseEvent | TouchEvent): boolean {
          startX = el.offsetLeft;
          startY = el.offsetTop;
          initialMouseX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
          initialMouseY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
          document.addEventListener("mousemove", move);
          document.addEventListener("mouseup", end);
          document.addEventListener("touchmove", move);
          document.addEventListener("touchend", end);
          return false;
        }

        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        window.addEventListener("resize", () => {
          el.style.left = Clamp(el.offsetLeft, 0, window.innerWidth - el.clientWidth) + "px";
          el.style.top = Clamp(el.offsetTop, 0, window.innerHeight - el.clientHeight) + "px";
          SetHeight();
        });
      },
    },
  },
})
export default class PlayOverlayMenu extends Vue {
  @Prop(String) fabDirection: string;
  @Prop(Boolean) muted: boolean;
  @Prop(Boolean) favorite: boolean;
  @Prop(Number) networkStrengh: number;

  fab = false;
  isFullscreen: boolean = Utils.Screenfull.isFullscreen;

  get showFullscreenButton(): boolean {
    return parser.getOS().name !== "iOS";
  }

  get showWarning(): boolean {
    return this.networkStrengh <= 0.33;
  }

  get showHelperText(): boolean {
    return !this.$store.direct.state.mobile.noMoreFabHelperText;
  }

  fabClick(): void {
    if (this.showHelperText && this.fab) this.$store.direct.commit.mobile.SET_NO_MORE_FAB_HELPER_TEXT(true);
  }

  get showStatsButton(): boolean {
    return (
      this.$store.direct.getters.authentication.hasPermission(UserPermission.SEE_FULL_WEBRTC_STATS) ||
      this.$store.direct.getters.authentication.hasPermission(UserPermission.SEE_BASIC_WEBRTC_STATS)
    );
  }

  created(): void {
    Utils.Screenfull?.onchange?.(() => (this.isFullscreen = Utils.Screenfull.isFullscreen));
  }
}
